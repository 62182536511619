import { Notification } from "@dtm-frontend/shared/notifications";
import { Address, PhoneNumber } from "@dtm-frontend/shared/ui";

export interface WebAppConversationNotification extends Notification {
    type: WebAppConversationNotificationType;
    payload: WebAppConversationNotificationRequestPayload | WebAppConversationNewMessageNotificationRequestPayload;
}

export interface WebAppMembershipInvitationNotification extends Notification {
    type: WebAppMembershipInvitationNotificationType;
    payload: WebAppMembershipInvitationNotificationRequestPayload;
}

export interface WebAppMembershipNotification extends Notification {
    type: WebAppMembershipNotificationType;
    payload: WebAppMembershipNotificationRequestPayload;
}

export enum WebAppConversationNotificationType {
    NewMessageToStakeholderEvent = "NewMessageToStakeholderEvent",
    ThreadClosedEvent = "ThreadClosedEvent",
}

export enum WebAppMembershipInvitationNotificationType {
    UserInvitedEvent = "UserInvitedEvent",
    UserRejectedInvitationEvent = "UserRejectedInvitationEvent",
    UserAcceptedInvitationEvent = "UserAcceptedInvitationEvent",
}

export enum WebAppMembershipNotificationType {
    MembershipDeactivatedEvent = "MembershipDeactivatedEvent",
}

export enum WebAppPermissionNotificationType {
    OperationalAuthorizationsGrantedEvent = "OperationalAuthorizationsGrantedEvent",
    OperationalAuthorizationWithdrawnEvent = "OperationalAuthorizationWithdrawnEvent",
    OperationalAuthorizationRemovedEvent = "OperationalAuthorizationRemovedEvent",
    OperationalAuthorizationSuspendedEvent = "OperationalAuthorizationSuspendedEvent",
    OperationalAuthorizationActivatedEvent = "OperationalAuthorizationActivatedEvent",
    OperationalAuthorizationSoonToExpireEvent = "OperationalAuthorizationSoonToExpireEvent",
    OperationalAuthorizationExpiredEvent = "OperationalAuthorizationExpiredEvent",
    OperationScenariosStatementPaymentStatusChangedEvent = "OperationScenariosStatementPaymentStatusChangedEvent",
}

export enum CompetenciesManagementNotificationType {
    CompetencyGrantedEvent = "CompetencyGrantedEvent",
    CompetencyActivatedEvent = "CompetencyActivatedEvent",
    CompetencyRemovedEvent = "CompetencyRemovedEvent",
    CompetencyWithdrawnEvent = "CompetencyWithdrawnEvent",
    CompetencySuspendedEvent = "CompetencySuspendedEvent",
}

export enum WebAppUserNotificationType {
    LegalGuardianUpdatedEvent = "LegalGuardianUpdatedEvent",
    UtmUserDataChangedEvent = "UtmUserDataChangedEvent",
    IdentityDocumentAcceptedEvent = "IdentityDocumentAcceptedEvent",
    IdentityDocumentRejectedEvent = "IdentityDocumentRejectedEvent",
}

export enum MissionPlanningNotificationType {
    MissionPlanningEvent = "MissionPlanningEvent",
}

export interface WebAppCompetenciesManagementNotification extends Notification {
    type: CompetenciesManagementNotificationType;
    payload: WebAppCompetenciesManagementNotificationRequestPayload;
}

export interface WebAppUserNotification extends Notification {
    type: WebAppUserNotificationType;
    payload: WebAppUserNotificationRequestPayload;
}

export interface WebAppPermissionNotification extends Notification {
    type: WebAppPermissionNotificationType;
    payload: WebAppPermissionNotificationRequestPayload;
}

export interface WebAppMissionPlanningNotification extends Notification {
    type: MissionPlanningNotificationType;
    payload: WebAppMissionPlanningNotificationRequestPayload;
}

export interface WebAppConversationNotificationRequestPayload {
    threadId: string;
    threadName: string;
    stakeholderId: string;
}

export interface WebAppMembershipInvitationNotificationRequestPayload {
    role: string;
    invitationId: string;
    invitationDate: string;
    sender: InvitationSender;
    recipient: InvitationRecipient;
    note?: string;
}

export interface WebAppMembershipNotificationRequestPayload {
    memberUserId: string;
    membershipId: string;
    membershipOperatorId: string;
    membershipOperatorName: string;
    membershipOperatorOwnerUserId: string;
    memberUserName: string;
    role: string;
    note?: string;
}

export type WebAppPermissionNotificationRequestPayload =
    | WebAppPermissionGrantedNotificationRequestPayload
    | WebAppPermissionSoonToExpiredNotificationRequestPayload
    | WebAppPermissionExpiredNotificationRequestPayload
    | WebAppStatementPaymentStatusChangedNotificationPayload
    | WebAppPermissionWithdrawnNotificationRequestPayload
    | WebAppPermissionRemovedNotificationRequestPayload
    | WebAppPermissionSuspendedNotificationRequestPayload
    | WebAppPermissionActivatedNotificationRequestPayload;

export type WebAppUserNotificationRequestPayload =
    | WebAppLegalGuardianUpdatedNotificationRequestPayload
    | WebAppUtmUserDataChangedNotificationRequestPayload
    | WebAppIdentityDocumentAcceptanceOrRejectionNotificationRequestPayload;

export interface WebAppLegalGuardianUpdatedNotificationRequestPayload {
    userId: string;
    firstName: string;
    dateOfBirth: string;
    nationality: string;
    lastName: string;
    phoneNumber: PhoneNumber;
    email: string;
    address: Address;
}

export interface WebAppUtmUserDataChangedNotificationRequestPayload {
    userId: string;
    firstName: string;
    lastName: string;
    nationality: string;
    easaMember: boolean;
    phoneNumber: PhoneNumber;
    email: string;
    dateOfBirth: string;
}

export interface WebAppIdentityDocumentAcceptanceOrRejectionNotificationRequestPayload {
    userId: string;
    languageTag: string;
}

export interface WebAppCompetenciesManagementNotificationRequestPayload {
    competency: ManagedCompetency;
    pilot: {
        competencies: ManagedCompetency[];
        firstName: string;
        id: string;
        integratedWithPansaUtm: boolean;
        lastName: string;
        operatorId: string;
        operatorNumber: string;
        pilotNumber: string;
        status: string;
        superPilot: boolean;
        suspendedReason: boolean;
        userId: string;
    };
}

interface ManagedCompetency {
    id: string;
    expirationDate: string;
    operationScenarioCategory: string;
    operationScenarioId: string;
    operationScenarioName: string;
    status: string;
    statusReason: string | null;
    valid: boolean;
}

export interface WebAppPermissionSoonToExpiredNotificationRequestPayload {
    operatorId: string;
    userId: string;
    operatorName: string;
    expirationDate: string;
    operationScenarioName: string;
    operationScenarioCategory: string;
}

export interface WebAppPermissionExpiredNotificationRequestPayload {
    operatorId: string;
    operatorName: string;
    userId: string;
    operationalAuthorizationId: string;
    operationScenarioName: string;
    operationScenarioCategory: string;
}

export interface WebAppStatementPaymentStatusChangedNotificationPayload {
    operatorId: string;
    operationScenariosStatementId: string;
    paymentStatus: StatementPaymentStatus;
    userId: string;
}

export interface WebAppPermissionActivatedNotificationRequestPayload {
    grantedScenarios: string;
    operationalAuthorizations: OperationalAuthorizations[];
    operator: WebAppPermissionNotificationOperator;
}

export enum StatementPaymentStatus {
    Completed = "COMPLETED",
    Error = "ERROR",
}

export interface WebAppPermissionGrantedNotificationRequestPayload {
    grantedScenarios: string;
    operationalAuthorizations: OperationalAuthorizations[];
    operator: WebAppPermissionNotificationOperator;
}

export interface WebAppPermissionWithdrawnNotificationRequestPayload {
    operationalAuthorizations: OperationalAuthorizations[];
    operator: WebAppPermissionNotificationOperator;
    withdrawalReason: string | null;
}

export interface WebAppPermissionRemovedNotificationRequestPayload {
    operationalAuthorizations: OperationalAuthorizations[];
    operator: WebAppPermissionNotificationOperator;
}

export interface WebAppPermissionSuspendedNotificationRequestPayload {
    operationalAuthorizations: OperationalAuthorizations[];
    operator: WebAppPermissionNotificationOperator;
    suspensionReason: string | null;
}

export interface WebAppMissionPlanningNotificationRequestPayload {
    translationId: string;
    args: Record<string, string>;
    target: {
        type: MissionPlanningNotificationTargetType;
        id: string;
    };
}

export enum MissionPlanningNotificationTargetType {
    Mission = "MISSION",
    Plan = "PLAN",
}

interface WebAppPermissionNotificationOperator {
    address: Address;
    attorneyPowerAdministrativeFee: boolean;
    attorneyPowers: AttorneyPower[];
    companyNumber: string;
    id: string;
    insurancePolicyNumber: string;
    logoId: string;
    name: string;
    ownerUserId: string;
    pilotId: string;
    registeredInOtherCountry: boolean;
    status: string;
    suspensionReason?: string;
    type?: string;
    operatorNumber: {
        number: string;
        secret: string;
    };
    insurancePolicyExpirationDate: string | null;
}

interface AttorneyPower {
    confirmatoryDocument: boolean;
    documentId?: string;
    granteeFirstName: string;
    granteeLastName: string;
    granteeUserId: string;
    id: string;
    nationalCourtRegister?: string;
    status: string;
    type: string;
}

interface OperationalAuthorizations {
    expirationDate: string;
    id?: string;
    operationScenarioCategory: string;
    operationScenarioId: string;
    operationScenarioName: string;
    status: string;
    statusReason?: string;
    uavSerialNumbers?: string[];
}

export interface WebAppConversationNewMessageNotificationRequestPayload extends WebAppConversationNotificationRequestPayload {
    sender: {
        firstName: string;
        lastName: string;
    };
}

export interface InvitationSender {
    userId: string;
    operatorId: string;
    operatorName: string;
}

export interface InvitationRecipient {
    userId: string;
    email?: string;
    phoneNumber?: PhoneNumber;
    firstName: string;
    lastName: string;
}
