import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Store } from "@ngxs/store";
import { lastValueFrom } from "rxjs";
import { UavActions } from "../state/uav.actions";
import { UavState } from "../state/uav.state";

export const UAV_ID_ROUTE_PARAM_NAME = "uavId";
export const SELECTED_SETUP_QUERY_PARAM_NAME = "selectedSetup";

@Injectable()
export class UavResolver {
    constructor(private readonly store: Store) {}

    public async resolve(route: ActivatedRouteSnapshot): Promise<string> {
        const uavId = route.paramMap.get(UAV_ID_ROUTE_PARAM_NAME) ?? "";

        const uavDetails = this.store.selectSnapshot(UavState.uavDetails);

        if (uavDetails?.id !== uavId) {
            await lastValueFrom(this.store.dispatch([new UavActions.GetCapabilities(), new UavActions.GetUav(uavId)]));
        }

        return uavId;
    }
}
