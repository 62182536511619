import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FunctionUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AcEntity, AcLayerComponent, AcNotification, ActionType, Cartesian3, CesiumService } from "@pansa/ngx-cesium";
import { Subject, combineLatest, mergeMap, of } from "rxjs";
import { MissionWithGeometry } from "../../../../models/mission-search.models";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

const DEFAULT_FILL_OPACITY = 0.8;
const AREA_FILL_COLOR = Cesium.Color.fromCssColorString("#007544"); // $color-status-success
const AREA_FILL = AREA_FILL_COLOR.withAlpha(DEFAULT_FILL_OPACITY);
const AREA_OUTLINE = AREA_FILL_COLOR;
const SELECTED_AREA_FILL_COLOR = Cesium.Color.fromCssColorString("#1d88ee"); // $color-secondary-600;
const SELECTED_AREA_FILL = SELECTED_AREA_FILL_COLOR.withAlpha(DEFAULT_FILL_OPACITY);
const SELECTED_AREA_OUTLINE = SELECTED_AREA_FILL_COLOR;
const AREA_OUTLINE_WIDTH = 2;

interface PolygonHierarchy {
    positions: Cartesian3[];
    holes?: PolygonHierarchy[];
}

interface MissionAreaAcEntity extends AcEntity {
    id: string;
    positions: PolygonHierarchy;
    style?: {
        fill?: any;
        outline?: any;
        outlineWidth?: any;
    };
    zIndex?: number;
}

interface MissionPinAcEntity extends AcEntity {
    id: string;
    planId: string;
    position: Cartesian3;
    show: boolean;
}

interface MissionGeometry extends MissionWithGeometry {
    isSelected: boolean;
}

interface MissionsLayerComponentState {
    missions: MissionGeometry[];
    isShown: boolean;
    missionsLayer: AcLayerComponent | undefined;
    pinsLayer: AcLayerComponent | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-mission-mission-search-missions-layer",
    templateUrl: "./missions-layer.component.html",
    styleUrls: ["./missions-layer.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissionsLayerComponent {
    @Input({ required: true }) public set missions(value: MissionGeometry[]) {
        this.localStore.patchState({ missions: value });
    }

    @Input() public set isShown(value: BooleanInput) {
        this.localStore.patchState({ isShown: coerceBooleanProperty(value) });
        this.cesiumService.getScene().requestRender();
    }

    @Output() public readonly missionSelect = new EventEmitter<string>();

    @ViewChild("missionsLayer") protected set missionsLayer(value: AcLayerComponent) {
        this.localStore.patchState({ missionsLayer: value });
    }

    @ViewChild("pinsLayer") protected set pinsLayer(value: AcLayerComponent) {
        this.localStore.patchState({ pinsLayer: value });
    }

    protected readonly isShown$ = this.localStore.selectByKey("isShown");
    private readonly missionsAreasEntitiesSubject = new Subject<AcNotification[]>();
    private readonly pinEntitiesSubject = new Subject<AcNotification[]>();
    protected readonly missionsAreasEntities$ = this.missionsAreasEntitiesSubject
        .asObservable()
        .pipe(mergeMap((entities) => of(...entities)));
    protected readonly pinEntities$ = this.pinEntitiesSubject.asObservable().pipe(mergeMap((entities) => of(...entities)));

    constructor(
        private readonly localStore: LocalComponentStore<MissionsLayerComponentState>,
        private readonly cesiumService: CesiumService
    ) {
        this.localStore.setState({
            missions: [],
            isShown: false,
            missionsLayer: undefined,
            pinsLayer: undefined,
        });

        this.watchMissionsChange();
    }

    private watchMissionsChange() {
        combineLatest([
            this.localStore.selectByKey("missionsLayer").pipe(RxjsUtils.filterFalsy()),
            this.localStore.selectByKey("pinsLayer").pipe(RxjsUtils.filterFalsy()),
            this.localStore.selectByKey("missions"),
        ])
            .pipe(untilDestroyed(this))
            .subscribe(async ([missionsLayer, pinsLayer, missions]) => {
                const missionsAreasEntities: MissionAreaAcEntity[] = [];
                const pinEntities: MissionPinAcEntity[] = [];

                for (const { planId, flightArea, isSelected } of missions) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const geoJsonData = (await Cesium.GeoJsonDataSource.load(flightArea)).entities.values as any[];
                    const polygonHierarchies: PolygonHierarchy[] = geoJsonData
                        .map((entity) => entity.polygon?.hierarchy?.valueOf())
                        .filter(FunctionUtils.isTruthy);

                    if (!polygonHierarchies.length) {
                        continue;
                    }

                    missionsAreasEntities.push(...this.getMissionEntities(polygonHierarchies, planId, isSelected));
                    pinEntities.push(this.getPinEntity(polygonHierarchies, planId));
                }

                const currentMissionEntitiesIds = new Set(Array.from(missionsLayer.getStore().keys()));
                this.missionsAreasEntitiesSubject.next(
                    this.getMissionAreaEntityNotifications(missionsAreasEntities, currentMissionEntitiesIds)
                );

                const currentPinEntitiesIds = new Set(Array.from(pinsLayer.getStore().keys()));
                this.pinEntitiesSubject.next(this.getMissionAreaEntityNotifications(pinEntities, currentPinEntitiesIds));

                this.cesiumService.getScene().requestRender();
            });
    }

    private getMissionEntities(polygonHierarchies: PolygonHierarchy[], planId: string, isSelected: boolean): MissionAreaAcEntity[] {
        return polygonHierarchies.map(
            (polygonHierarchy: PolygonHierarchy, index: number): MissionAreaAcEntity => ({
                id: `area_${planId}_${index}`,
                positions: polygonHierarchy,
                style: {
                    fill: isSelected ? SELECTED_AREA_FILL : AREA_FILL,
                    outline: isSelected ? SELECTED_AREA_OUTLINE : AREA_OUTLINE,
                    outlineWidth: AREA_OUTLINE_WIDTH,
                },
                zIndex: +isSelected, // NOTE: ensure that selected area is shown above the others
            })
        );
    }

    private getPinEntity(polygonHierarchies: PolygonHierarchy[], planId: string): MissionPinAcEntity {
        const areaCenter = Cesium.BoundingSphere.fromPoints(polygonHierarchies[0].positions).center;

        return {
            id: `pin_${planId}`,
            planId,
            position: areaCenter,
            show: true,
        };
    }

    private getMissionAreaEntityNotifications(
        entities: MissionAreaAcEntity[] | MissionPinAcEntity[],
        unusedIds: Set<string>
    ): AcNotification[] {
        const result: AcNotification[] = entities.map((entity) => {
            unusedIds.delete(entity.id);

            return {
                entity,
                actionType: ActionType.ADD_UPDATE,
                id: entity.id,
            };
        });

        unusedIds.forEach((entityId) =>
            result.push({
                id: entityId,
                actionType: ActionType.DELETE,
            })
        );

        return result;
    }
}
