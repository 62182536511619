import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { MissionProcessingPhaseExtended, MissionType } from "../../models/mission.models";

interface BasicDetailsComponentState {
    startDate: Date;
    finishDate: Date;
    plannedStartTime: Date | undefined;
    plannedFinishTime: Date | undefined;
    phase: MissionProcessingPhaseExtended | undefined;
    missionType: MissionType | undefined;
    isAuthorityAcceptationConfirmed: boolean | undefined;
    isWholeDayMode: boolean;
}

@Component({
    selector: "dtm-mission-basic-details[startDate][finishDate]",
    templateUrl: "./basic-details.component.html",
    styleUrls: ["./basic-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class BasicDetailsComponent {
    @Input() public set startDate(value: Date) {
        this.localStore.patchState({ startDate: value });
    }
    @Input() public set finishDate(value: Date) {
        this.localStore.patchState({ finishDate: value });
    }
    @Input() public set plannedStartTime(value: Date | undefined) {
        this.localStore.patchState({ plannedStartTime: value });
    }
    @Input() public set plannedFinishTime(value: Date | undefined) {
        this.localStore.patchState({ plannedFinishTime: value });
    }
    @Input() public set phase(value: MissionProcessingPhaseExtended | undefined) {
        this.localStore.patchState({ phase: value });
    }
    @Input() public set missionType(value: MissionType | undefined) {
        this.localStore.patchState({ missionType: value });
    }
    @Input() public set isAuthorityAcceptationConfirmed(value: BooleanInput | undefined) {
        this.localStore.patchState({ isAuthorityAcceptationConfirmed: value === undefined ? undefined : coerceBooleanProperty(value) });
    }
    @Input() public set isWholeDayMode(value: BooleanInput) {
        this.localStore.patchState({ isWholeDayMode: coerceBooleanProperty(value) });
    }

    @Output() public readonly zoom = new EventEmitter<void>();
    @Output() public readonly messageShow = new EventEmitter<void>();

    protected readonly startDate$ = this.localStore.selectByKey("startDate");
    protected readonly finishDate$ = this.localStore.selectByKey("finishDate");
    protected readonly plannedStartTime$ = this.localStore.selectByKey("plannedStartTime");
    protected readonly plannedFinishTime$ = this.localStore.selectByKey("plannedFinishTime");
    protected readonly phase$ = this.localStore.selectByKey("phase");
    protected readonly missionType$ = this.localStore.selectByKey("missionType");
    protected readonly isAuthorityAcceptationConfirmed$ = this.localStore.selectByKey("isAuthorityAcceptationConfirmed");
    protected readonly isWholeDayMode$ = this.localStore.selectByKey("isWholeDayMode");

    constructor(private readonly localStore: LocalComponentStore<BasicDetailsComponentState>) {
        this.localStore.setState({
            startDate: new Date(),
            finishDate: new Date(),
            plannedStartTime: undefined,
            plannedFinishTime: undefined,
            phase: undefined,
            missionType: undefined,
            isAuthorityAcceptationConfirmed: undefined,
            isWholeDayMode: false,
        });
    }
}
