<mat-card *ngrxLet="{ isShortMode: isShortMode$ } as vm" [class.short-mode]="vm.isShortMode">
    <dtm-uav-lib-uav-info [uav]="uavInfo$ | ngrxPush" [isShortMode]="vm.isShortMode">
        <ng-content select="[actionsSlot]" ngProjectAs="[actionsSlot]"></ng-content>
    </dtm-uav-lib-uav-info>
    <dtm-uav-lib-uav-model-documents
        *ngIf="!vm.isShortMode"
        [uav]="uav$ | ngrxPush"
        [isManageButtonVisible]="isManageDocumentsButtonVisible$ | ngrxPush"
        (manage)="documentsManage.emit()"
    />
</mat-card>
