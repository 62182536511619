import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
    AZURE_MAPS_LAYER_OPTIONS,
    CameraHelperService,
    DEFAULT_CESIUM_VIEWER_CONFIGURATION_OPTIONS,
} from "@dtm-frontend/shared/map/cesium";
import { GeoJSON } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { SceneMode, ViewerConfiguration } from "@pansa/ngx-cesium";
import turfBbox from "@turf/bbox";
import { combineLatest } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { MissionWithGeometry } from "../../../models/mission-search.models";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

interface ResultMapComponentState {
    missions: MissionWithGeometry[];
    isProcessing: boolean;
    selectedMissionId: string | undefined;
    isMeasureToolActive: boolean;
}

// NOTE: initial viewbox set to Poland
const INITIAL_VIEWBOX: GeoJSON = {
    type: "Polygon",
    coordinates: [
        [
            /* eslint-disable no-magic-numbers */
            [14.069638889, 49.0020432310001],
            [14.069638889, 55.849716667],
            [24.150833333, 55.849716667],
            [24.150833333, 49.0020432310001],
            [14.069638889, 49.0020432310001],
            /* eslint-enable no-magic-numbers */
        ],
    ],
};

@UntilDestroy()
@Component({
    selector: "dtm-mission-mission-search-result-map",
    templateUrl: "./result-map.component.html",
    styleUrls: ["./result-map.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ResultMapComponent implements OnInit {
    @Input() public set missions(value: MissionWithGeometry[] | undefined) {
        this.localStore.patchState({ missions: value ?? [] });
    }

    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set selectedMissionId(value: string | undefined) {
        this.localStore.patchState({ selectedMissionId: value });

        if (value) {
            this.zoomToMission(value);
        } else {
            // eslint-disable-next-line no-magic-numbers
            this.cameraHelperService.flyToGeoJSON(this.getAllMissionsViewBox(), 0.8);
        }
    }

    @Output() public readonly selectedMissionChange = new EventEmitter<string>();

    protected readonly missions$ = this.localStore
        .selectByKey("missions")
        .pipe(RxjsUtils.filterFalsy(), shareReplay({ bufferSize: 1, refCount: true }));
    protected readonly missionsGeometries$ = combineLatest([this.missions$, this.localStore.selectByKey("selectedMissionId")]).pipe(
        map(([missions, selectedMissionId]) => this.convertMissionsToMissionsGeometries(missions, selectedMissionId))
    );
    protected readonly hasBackdrop$ = this.missions$.pipe(map((missions) => !missions.length));
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly isMeasureToolActive$ = this.localStore.selectByKey("isMeasureToolActive");

    protected readonly AZURE_MAPS_LAYER_OPTIONS = AZURE_MAPS_LAYER_OPTIONS;
    protected readonly SceneMode = SceneMode;

    constructor(
        private readonly localStore: LocalComponentStore<ResultMapComponentState>,
        private readonly cameraHelperService: CameraHelperService,
        viewerConfiguration: ViewerConfiguration
    ) {
        this.localStore.setState({
            missions: [],
            isProcessing: false,
            selectedMissionId: undefined,
            isMeasureToolActive: false,
        });

        viewerConfiguration.viewerOptions = {
            ...DEFAULT_CESIUM_VIEWER_CONFIGURATION_OPTIONS,
            sceneMode: SceneMode.SCENE3D,
        };

        this.watchMissionsChange();
    }

    public ngOnInit() {
        this.initView();
    }

    public zoomToMission(planId: string) {
        const mission = this.localStore.selectSnapshotByKey("missions").find((missionItem) => missionItem.planId === planId);

        if (mission) {
            // eslint-disable-next-line no-magic-numbers
            this.cameraHelperService.flyToGeoJSON(mission.flightArea, 0.8);
        }
    }

    protected updateMeasureToolStatus(isActive: boolean) {
        this.localStore.patchState({ isMeasureToolActive: isActive });
    }

    private initView() {
        Cesium.Camera.DEFAULT_VIEW_FACTOR = 0;
        Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(...turfBbox(INITIAL_VIEWBOX));
        // eslint-disable-next-line no-magic-numbers
        this.cameraHelperService.flyToGeoJSON(INITIAL_VIEWBOX, 0.1);
    }

    private watchMissionsChange() {
        this.missions$
            .pipe(
                map((missions) => {
                    if (!missions.length) {
                        return false;
                    }

                    // NOTE: setTimeout is needed to make sure that the camera is set after the map is initialized
                    // eslint-disable-next-line no-magic-numbers
                    setTimeout(() => this.cameraHelperService.flyToGeoJSON(this.getAllMissionsViewBox(missions), 0.5));

                    return true;
                }),
                RxjsUtils.filterFalsy(),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private getAllMissionsViewBox(missions: MissionWithGeometry[] = this.localStore.selectSnapshotByKey("missions")) {
        return {
            type: "MultiPolygon",
            coordinates: missions.reduce<MissionWithGeometry["flightArea"]["coordinates"]>(
                (acc, mission) => [...acc, ...mission.flightArea.coordinates],
                []
            ),
        };
    }

    private convertMissionsToMissionsGeometries(missionsGeometries: MissionWithGeometry[], selectedMissionId: string | undefined) {
        return missionsGeometries.map((missionGeometry) => ({
            ...missionGeometry,
            isSelected: missionGeometry.planId === selectedMissionId,
        }));
    }
}
