import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { Store } from "@ngxs/store";
import { map } from "rxjs/operators";
import { UavErrorType } from "../../services/uav.models";
import { SELECTED_SETUP_QUERY_PARAM_NAME } from "../../services/uav.resolvers";
import { UavState } from "../../state/uav.state";

@Component({
    selector: "dtm-admin-lib-uav-card",
    templateUrl: "./uav-card.component.html",
    styleUrls: ["./uav-card.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UavCardComponent {
    protected readonly UavErrorType = UavErrorType;
    protected readonly uav$ = this.store.select(UavState.uavDetails).pipe(RxjsUtils.filterFalsy());
    protected readonly trackers$ = this.store.select(UavState.trackers).pipe(RxjsUtils.filterFalsy());
    protected readonly navigationAccuracyItems$ = this.store.select(UavState.navigationAccuracyItems);
    protected readonly isProcessing$ = this.store.select(UavState.isProcessing);
    protected readonly uavError$ = this.store.select(UavState.uavError).pipe(RxjsUtils.filterFalsy());
    protected readonly capabilitiesError$ = this.store.select(UavState.capabilitiesError).pipe(RxjsUtils.filterFalsy());
    protected readonly selectedSetupId$ = this.route.queryParams.pipe(map((params) => params[SELECTED_SETUP_QUERY_PARAM_NAME]));

    constructor(private readonly route: ActivatedRoute, private readonly store: Store) {}
}
