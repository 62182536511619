<div class="grid">
    <mat-card>
        <dtm-ui-card-header>
            {{ "dtmAdminLibUav.uavList.header" | transloco }}
        </dtm-ui-card-header>
        <dtm-admin-lib-uav-filters
            [initialFilters]="initialFilters$ | ngrxPush"
            (filtersChange)="updateFiltersState($event)"
        ></dtm-admin-lib-uav-filters>
        <dtm-admin-lib-uav-list
            #uavList
            *ngIf="!(uavsError$ | ngrxPush); else errorTemplate"
            [uavs]="uavs$ | ngrxPush"
            [pagination]="pagination$ | ngrxPush"
            [isProcessing]="isProcessing$ | ngrxPush"
            (pageChange)="pageChange($event)"
            (uavListRefresh)="refreshUavList()"
        ></dtm-admin-lib-uav-list>
        <ng-template #errorTemplate>
            <dtm-ui-error [mode]="ErrorMode.Secondary" (reload)="refreshUavList()"> </dtm-ui-error>
        </ng-template>
    </mat-card>
</div>
