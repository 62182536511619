import { MissionType } from "@dtm-frontend/shared/mission";
import {
    AdjacentGroundAreaCharacteristicType,
    AirRiskMitigationType,
    AvoidingFlightsOverPeopleType,
    CaaContactPerson,
    Competency,
    ContactPerson,
    ContainmentLevelReachedType,
    ControlledGroundAreaCharacteristicType,
    DtmLocations,
    GroundAreaCharacteristicType,
    GroundRiskMitigationType,
    LocalizationType,
    OperationalAirspaceClassType,
    OperationalArc,
    Permit,
    PermitUavModel,
    RiskAssessmentReference,
    Sail,
    TacticalAirRiskMitigationType,
} from "@dtm-frontend/shared/ui";

export interface RawPermitUavDetails {
    modelId: string;
    operatedNumber: number;
    serialNumbers: string;
    maxWidth: number;
    takeOffMass: number;
    maxFlightSpeed: number;
    additionalTechRequirements: string;
    designVerificationReport: string;
    certificateOfAirworthiness: string;
    noiseCertificate: string;
    groundRiskMitigation: GroundRiskMitigationType;
    isSwarm: boolean;
}

export interface RawPermitDetails {
    id: string;
    operatorName: string;
    operatorNumber: string;
    createdAt: Date;
    caaContact: CaaContactPerson;
    operatorId: string;
    operatorContact: ContactPerson;
    permitNumber: string;
    permitConfirmationNumber?: string;
    validityPeriodStart: Date;
    validityPeriodFinish: Date;
    flightPurposes: string;
    location: {
        kmlFileId?: string;
        kmlFileName?: string;
        dtmId?: string;
        type?: LocalizationType;
        description?: string;
    };
    riskAssessmentReference: RiskAssessmentReference;
    pdraNumber?: string;
    assuranceAndIntegrityLevel: Sail;
    operationType: MissionType;
    isDangerousGoodsTransport: boolean;
    groundAreaCharacteristic: GroundAreaCharacteristicType;
    controlledGroundAreaCharacteristic?: ControlledGroundAreaCharacteristicType;
    finalGroundRiskClass: number;
    adjacentGroundAreaCharacteristic: AdjacentGroundAreaCharacteristicType;
    adjacentGroundAreaExtent?: number;
    operationalVolumeHeightLimit: number;
    operationalAirSpaces: OperationalAirspaceClassType[];
    otherAirspace?: string;
    operationalArc: OperationalArc;
    adjacentArc: OperationalArc;
    strategicGroundRiskMitigation: GroundRiskMitigationType;
    emergencyResponsePlan?: GroundRiskMitigationType;
    avoidingFlightsOverPeople?: AvoidingFlightsOverPeopleType;
    strategicAirRiskMitigations: string[];
    tacticalAirRiskMitigation: TacticalAirRiskMitigationType;
    tacticalAirRiskMitigationRequirements?: string;
    achievedContainmentLevel: ContainmentLevelReachedType;
    pilotCompetencies: string[];
    pilotAdditionalCompetencies?: string[];
    pilotCompetenciesFreeText?: string;
    personnelCompetencies?: string[];
    personnelCompetenciesFreeText?: string;
    isInsurance: boolean;
    operationsManualReference: string;
    additionalLimitations: string;
    uav: RawPermitUavDetails;
    remarks?: string;
    uavModel: PermitUavModel;
}

function getAirRiskMitigationType(value: string[]): AirRiskMitigationType {
    if (value.length > 1) {
        return AirRiskMitigationType.OperationalMitigationAndCommonRulesAndAirspace;
    }
    if (value.includes("OPERATIONAL_MITIGATION")) {
        return AirRiskMitigationType.OperationalMitigation;
    }
    if (value.includes("COMMON_RULES_AND_AIRSPACE")) {
        return AirRiskMitigationType.CommonRulesAndAirspace;
    }

    return AirRiskMitigationType.Absence;
}

export function getPermitWithCapabilities(permitDetails: RawPermitDetails, competencies: Competency[], dtm: DtmLocations[]): Permit {
    return {
        id: permitDetails.id,
        basicData: {
            caaContactUser: { ...permitDetails.caaContact, phoneNumber: undefined },
            operator: {
                name: permitDetails.operatorName,
                id: permitDetails.operatorId,
                number: permitDetails.operatorNumber,
            },
            operatorContact: permitDetails.operatorContact,
            operationRestrictions: {
                permitNumber: permitDetails.permitNumber,
                permitConfirmationNumber: permitDetails.permitConfirmationNumber,
                expirationDate: {
                    dateFrom: permitDetails.validityPeriodStart,
                    dateTo: permitDetails.validityPeriodFinish,
                },
                operationTarget: permitDetails.flightPurposes,
                localization: permitDetails.location.type,
                dtm: dtm.filter((location) => location.id === permitDetails.location.dtmId)[0],
                kmlFile:
                    permitDetails.location.kmlFileId && permitDetails.location.kmlFileName
                        ? [{ id: permitDetails.location.kmlFileId, name: permitDetails.location.kmlFileName }]
                        : [],
                locationDescription: permitDetails.location.description ?? "",
                riskMethodology: permitDetails.riskAssessmentReference,
                pdraNumber: permitDetails.pdraNumber ?? null,
                sail: permitDetails.assuranceAndIntegrityLevel,
                operationType: permitDetails.operationType,
                isDangerousGoodsTransport: permitDetails.isDangerousGoodsTransport,
            },
            groundRiskCharacteristic: {
                groundAreaCharacteristic: permitDetails.groundAreaCharacteristic,
                controlledGroundAreaCharacteristic: permitDetails.controlledGroundAreaCharacteristic,
                finalGroundRiskClass: permitDetails.finalGroundRiskClass,
                adjacentGroundAreaCharacteristic: permitDetails.adjacentGroundAreaCharacteristic,
                adjacentGroundAreaExtent: permitDetails.adjacentGroundAreaExtent,
            },
            operationalVolumeHeight: {
                operationalVolumeHeightLimit: permitDetails.operationalVolumeHeightLimit,
            },
            finalRiskArea: {
                operationArea: permitDetails.operationalArc,
                adjacentArea: permitDetails.adjacentArc,
                operationalAirSpaces: permitDetails.operationalAirSpaces,
                otherAirspace: permitDetails.otherAirspace,
            },
            groundRiskMitigation: {
                strategicMitigationMeasure: permitDetails.strategicGroundRiskMitigation,
                avoidingFlightsOverPeople: permitDetails.avoidingFlightsOverPeople,
                emergencyResponsePlan: permitDetails.emergencyResponsePlan,
            },
            airRiskMitigation: {
                strategicMitigationMeasure: getAirRiskMitigationType(permitDetails.strategicAirRiskMitigations),
                tacticalAirRiskMitigationMeasure: permitDetails.tacticalAirRiskMitigation,
                tacticalAirRiskMitigationRequirements: permitDetails.tacticalAirRiskMitigationRequirements,
                competencies: competencies.filter((competency) => permitDetails.pilotCompetencies.includes(competency.id)),
                additionalCompetencies: permitDetails.pilotAdditionalCompetencies ?? [],
                competenciesDescription: permitDetails.pilotCompetenciesFreeText ?? "",
                staffCompetencies: permitDetails.personnelCompetencies ?? [],
                staffCompetenciesDescription: permitDetails.personnelCompetenciesFreeText ?? "",
            },
            additionalAccidentType: {
                hasInsurance: permitDetails.isInsurance,
                operationsManualReference: permitDetails.operationsManualReference,
                additionalLimitations: permitDetails.additionalLimitations,
            },
        },
        uavDetails: {
            uavModel: permitDetails.uavModel,
            operatedNumber: permitDetails.uav.operatedNumber,
            isDroneSwarm: permitDetails.uav.isSwarm,
            serialNumbers: permitDetails.uav.serialNumbers,
            maxUavWidth: permitDetails.uav.maxWidth,
            maxFlightSpeed: permitDetails.uav.maxFlightSpeed,
            takeOffWeight: permitDetails.uav.takeOffMass,
            additionalTechRequirements: permitDetails.uav.additionalTechRequirements,
            designVerificationReport: permitDetails.uav.designVerificationReport,
            certificateOfAirworthiness: permitDetails.uav.certificateOfAirworthiness,
            noiseCertificate: permitDetails.uav.noiseCertificate,
            riskMitigation: permitDetails.uav.groundRiskMitigation,
            containmentReachedLevel: permitDetails.achievedContainmentLevel,
            comments: permitDetails.remarks,
        },
    };
}
