<ng-container
    *ngrxLet="{
        flightConditions: flightConditions$,
        activeLanguageCode: activeLanguageCode$,
        analysisIssues: analysisIssues$,
        evaluationIssues: evaluationIssues$,
        additionalRequirements: additionalRequirements$,
        flightRules: flightRules$,
        isWaiting: isWaiting$,
        isRejected: isRejected$,
    } as vm"
>
    <div
        class="requirements-panel"
        *ngIf="
            (vm.isWaiting && (vm.flightConditions?.length || vm.evaluationIssues.length)) ||
            vm.additionalRequirements.length ||
            (!vm.isWaiting && !vm.isRejected && vm.flightRules.length)
        "
    >
        <p class="sub-header">{{ "dtmSharedMission.analysis.zone.requirementsLabel" | transloco }}</p>
        <p class="requirements" *ngFor="let requirements of vm.additionalRequirements">
            <dtm-ui-icon [ngClass]="requirements.className" [name]="requirements.iconName"></dtm-ui-icon>
            <span class="requirements-text">{{ requirements.text }}</span>
        </p>
        <ng-container *ngIf="vm.isWaiting">
            <div class="requirements" *ngFor="let flightCondition of vm.flightConditions">
                <dtm-ui-icon class="warning" name="error-warning-fill"></dtm-ui-icon>
                <p>
                    <span class="requirements-text" [innerHTML]="flightCondition.text.trim() | safeHtml"> </span>&nbsp;<dtm-ui-popover
                        *ngIf="flightCondition.tooltip"
                    >
                        <p [innerHTML]="flightCondition.tooltip | safeHtml"></p>
                    </dtm-ui-popover>
                </p>
            </div>
            <div class="requirements" *ngFor="let issue of vm.evaluationIssues" [ngSwitch]="issue.status">
                <dtm-ui-icon class="warning" *ngSwitchCase="EvaluationIssueStatus.Open" name="error-warning-fill"></dtm-ui-icon>
                <dtm-ui-icon class="error" *ngSwitchCase="EvaluationIssueStatus.Rejected" name="sign-prohibition"></dtm-ui-icon>
                <div>
                    <span class="requirements-text"> {{ issue | translateAnalysisIssue }}</span>
                    <p class="manual-verification" *ngIf="issue.status === EvaluationIssueStatus.Open">
                        {{ "dtmSharedMission.analysis.manualVerificationRequiredLabel" | transloco }}
                    </p>
                </div>
            </div>

            <p class="requirements" *ngFor="let issue of vm.analysisIssues">
                <dtm-ui-icon class="info" name="information-fill"></dtm-ui-icon>
                <span class="requirements-text"> {{ issue | translateAnalysisIssue }}</span>
            </p>
        </ng-container>

        <ng-container *ngIf="!vm.isWaiting && !vm.isRejected && vm.flightRules?.length">
            <p class="rules-label" dtmUiElementObserver (elementResize)="checkAndUpdateExpandFeatures(rulesList, expandButton)">
                {{ "dtmSharedMission.flightRequirements.flightRulesLabel" | transloco }}
            </p>
            <ol class="rules-list" #rulesList>
                <li
                    *ngFor="let condition of vm.flightRules"
                    [innerHTML]="condition.translationId | systemTranslation : condition.translationArgs"
                ></li>
            </ol>
            <div class="expand hidden" #expandButton>
                <button type="button" class="button-tertiary" (click)="toggleRulesVisibility(rulesList)">
                    <span>
                        {{
                            "dtmSharedMission.flightRequirements.expandLabel"
                                | transloco : { value: rulesList.classList.contains("expanded") }
                        }}
                    </span>
                    <dtm-ui-icon [name]="rulesList.classList.contains('expanded') ? 'arrow-up' : 'arrow-down'"></dtm-ui-icon>
                </button>
            </div>
        </ng-container>
    </div>
</ng-container>
