import { Injectable, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthState } from "@dtm-frontend/shared/auth";
import { NotificationsApiService } from "@dtm-frontend/shared/notifications";
import { ContextOperator, DialogService } from "@dtm-frontend/shared/ui";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { Actions, Store, ofActionDispatched } from "@ngxs/store";
import { Observable, Subscription, combineLatest, of } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import { WebAppMembershipNotification, WebAppMembershipNotificationType } from "../../notifications/services/web-app-notifications.models";
import { OperatorRemovalInfoDialogComponent } from "../components/operator-removal-info-dialog/operator-removal-info-dialog.component";
import { OPERATOR_CONTEXT_PARAM } from "../models/operator-context.models";
import { OperatorContextActions } from "../state/operator-context.actions";
import { OperatorContextState } from "../state/operator-context.state";

@Injectable({
    providedIn: "root",
})
export class UserContextService implements OnDestroy {
    private readonly tries: Set<Observable<boolean>> = new Set([of(true)]);
    private membershipDeactivationEventsSubscription: Subscription | undefined;

    constructor(
        private readonly actions$: Actions,
        private readonly store: Store,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly notificationsApiService: NotificationsApiService,
        private readonly dialogService: DialogService
    ) {}

    public init() {
        this.observeContextSwitchTry();
        this.observeSelectedContext();
        this.observeQueryRouteParams();
        this.observeMembershipDeactivationEvents();
    }

    public registerContextSwitchTry(try$: Observable<boolean>): void {
        this.tries.add(try$);
    }

    public unregisterContextSwitchTry(try$: Observable<boolean>): void {
        this.tries.delete(try$);
    }

    public ngOnDestroy(): void {
        this.membershipDeactivationEventsSubscription?.unsubscribe();
    }

    private observeContextSwitchTry(): void {
        this.actions$
            .pipe(
                ofActionDispatched(OperatorContextActions.TrySelectContext),
                map(({ operator }) => operator),
                RxjsUtils.filterFalsy(),
                filter((operator) => operator.id !== this.store.selectSnapshot(OperatorContextState.selectedContextId)),
                switchMap((operator) =>
                    combineLatest(Array.from(this.tries)).pipe(
                        map((tries) => tries.every(Boolean)),
                        RxjsUtils.filterFalsy(),
                        map(() => operator)
                    )
                )
            )
            .subscribe((operator) => this.store.dispatch(new OperatorContextActions.SelectContext(operator)));
    }

    private observeSelectedContext(): void {
        this.store
            .select(OperatorContextState.selectedContext)
            .pipe(RxjsUtils.filterFalsy())
            .subscribe((operator) => {
                this.persistSelectedContext(operator);
                this.changeQueryParam(operator);
            });
    }

    private persistSelectedContext(operator: ContextOperator): void {
        localStorage.setItem(OPERATOR_CONTEXT_PARAM, operator.id);
    }

    private changeQueryParam(operator: ContextOperator): void {
        if ((this.route.snapshot.queryParams[OPERATOR_CONTEXT_PARAM] ?? operator.id) === operator.id) {
            return;
        }

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { [OPERATOR_CONTEXT_PARAM]: operator.id },
            queryParamsHandling: "merge",
        });
    }

    private observeQueryRouteParams(): void {
        this.route.queryParamMap
            .pipe(
                map((params) => params.get(OPERATOR_CONTEXT_PARAM)),
                RxjsUtils.filterFalsy(),
                filter((operatorContextId) => operatorContextId !== this.store.selectSnapshot(OperatorContextState.selectedContext)?.id),
                switchMap((operatorContextId) =>
                    this.store.select(OperatorContextState.operator(operatorContextId)).pipe(RxjsUtils.filterFalsy())
                )
            )
            .subscribe((operator) => {
                this.store.dispatch(new OperatorContextActions.TrySelectContext(operator));
            });
    }

    private observeMembershipDeactivationEvents(): void {
        this.membershipDeactivationEventsSubscription = this.notificationsApiService
            .startIncomingNotificationsWatch<
                WebAppMembershipNotificationType.MembershipDeactivatedEvent,
                WebAppMembershipNotification["payload"]
            >(this.store.selectSnapshot(AuthState.userId), [WebAppMembershipNotificationType.MembershipDeactivatedEvent])
            .pipe(
                RxjsUtils.filterFalsy(),
                filter(({ payload: { memberUserId }, userId }) => memberUserId === userId),
                switchMap(({ payload }) => {
                    if (payload.membershipOperatorId === this.store.selectSnapshot(OperatorContextState.selectedContextId)) {
                        return this.openContextRemovedInfoDialog(payload.membershipOperatorName);
                    }

                    return of(false);
                }),
                switchMap((shouldRedirect) =>
                    this.store.dispatch(new OperatorContextActions.GetGlobalCapabilities()).pipe(map(() => shouldRedirect))
                )
            )
            .subscribe((shouldRedirect) => {
                if (shouldRedirect) {
                    this.router.navigate(["/"]);
                }
            });
    }

    private openContextRemovedInfoDialog(operatorName: string) {
        return this.dialogService
            .open(OperatorRemovalInfoDialogComponent, {
                data: {
                    operatorName,
                },
                closeOnNavigation: false,
                disableClose: true,
            })
            .afterClosed()
            .pipe(map(() => true));
    }
}
