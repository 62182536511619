<ng-container *ngrxLet="{ properties: properties$, isNarrowMode: isNarrowMode$ } as vm">
    <section>
        <dtm-ui-expandable-panel *ngIf="vm.properties.technicalSpecification" [hasHeaderSeparator]="false" [isExpanded]="true">
            <h4 class="section-title" headerSlot>
                {{ "dtmSharedUav.setupProperties.flightTechnicalProperties.sectionHeader" | transloco }}
            </h4>
            <dtm-uav-lib-flight-technical-properties [properties]="vm.properties.technicalSpecification" [isNarrow]="vm.isNarrowMode" />
        </dtm-ui-expandable-panel>
        <dtm-ui-expandable-panel *ngIf="vm.properties.technicalSpecification" [hasHeaderSeparator]="false" [isExpanded]="true">
            <h4 class="section-title" headerSlot>
                {{ "dtmSharedUav.setupProperties.generalTechnicalProperties.sectionHeader" | transloco }}
            </h4>
            <dtm-uav-lib-other-technical-properties [properties]="vm.properties.technicalSpecification" [isNarrow]="vm.isNarrowMode" />
        </dtm-ui-expandable-panel>
        <dtm-ui-expandable-panel
            *ngFor="let communication of vm.properties.communications"
            [hasHeaderSeparator]="false"
            [isExpanded]="true"
        >
            <div headerSlot>
                <h4 class="section-title">
                    {{ "dtmSharedUav.setupProperties.communicationProperties.sectionHeader" | transloco }}
                </h4>
                <span class="chip light">
                    {{
                        communication.isEmbedded
                            ? ("dtmSharedUav.setupProperties.communicationProperties.isEmbeddedLabel" | transloco)
                            : ("dtmSharedUav.setupProperties.communicationProperties.isCustomLabel" | transloco)
                    }}
                </span>
            </div>
            <dtm-uav-lib-communication-properties [properties]="communication" [isNarrow]="vm.isNarrowMode" />
        </dtm-ui-expandable-panel>
        <dtm-ui-expandable-panel *ngIf="vm.properties.documents" [hasHeaderSeparator]="false" [isExpanded]="true">
            <div headerSlot>
                <h2 class="section-title">
                    {{ "dtmSharedUav.uavDocuments.setup.sectionHeader" | transloco }}
                </h2>
            </div>
            <dtm-ui-files-groups-display [groups]="vm.properties.documents" />
        </dtm-ui-expandable-panel>
    </section>
    <section class="equipment">
        <ng-container *ngIf="vm.properties.trackings?.length || vm.properties.equipment.length; else emptyEquipmentTemplate">
            <ng-container *ngrxLet="trackers$ as trackers">
                <dtm-ui-expandable-panel *ngFor="let tracking of vm.properties.trackings" [hasHeaderSeparator]="false" [isExpanded]="true">
                    <div headerSlot>
                        <h4 class="section-title">
                            {{ "dtmSharedUav.setupProperties.trackings.sectionHeader" | transloco }}
                        </h4>
                        <span class="chip light">
                            {{
                                tracking.isEmbedded
                                    ? ("dtmSharedUav.setupProperties.trackings.isEmbeddedLabel" | transloco)
                                    : ("dtmSharedUav.setupProperties.trackings.isCustomLabel" | transloco)
                            }}
                        </span>
                    </div>
                    <dtm-uav-lib-tracking-properties
                        [properties]="tracking"
                        [trackers]="trackers"
                        [navigationAccuracyItems]="navigationAccuracyItems$ | ngrxPush"
                    />
                </dtm-ui-expandable-panel>
            </ng-container>

            <dtm-ui-expandable-panel *ngFor="let equipmentItem of vm.properties.equipment" [hasHeaderSeparator]="false" isExpanded>
                <div headerSlot>
                    <h4 class="section-title">
                        <ng-container *ngIf="equipmentItem.item.name">
                            {{
                                "dtmSharedUav.setupProperties.equipment.typeWithNameLabel"
                                    | transloco
                                        : {
                                              type:
                                                  ("dtmSharedUav.setupProperties.equipment.typeValue"
                                                  | transloco : { value: equipmentItem.type }),
                                              name: equipmentItem.item.name
                                          }
                            }}
                        </ng-container>
                        <ng-container *ngIf="!equipmentItem.item.name">
                            {{ "dtmSharedUav.setupProperties.equipment.typeValue" | transloco : { value: equipmentItem.type } }}
                        </ng-container>
                    </h4>
                    <span class="chip light" *ngIf="equipmentItem.type === EquipmentType.Parachute && equipmentItem.item.isEmbedded">
                        {{ "dtmSharedUav.setupProperties.equipment.parachuteIsEmbeddedLabel" | transloco }}
                    </span>
                </div>
                <dtm-uav-lib-equipment-properties [properties]="equipmentItem" />
            </dtm-ui-expandable-panel>
        </ng-container>

        <ng-template #emptyEquipmentTemplate>
            <dtm-ui-empty-state *ngIf="isEmptyEquipmentVisible$ | ngrxPush" [mode]="EmptyStateMode.Secondary">
                <ng-container messageSlot>
                    <span #emptyEquipmentMessageContent><ng-content select="[emptyEquipmentMessageSlot]"></ng-content></span>
                    <ng-container *ngIf="emptyEquipmentMessageContent | isEmptyElement">
                        {{ "dtmSharedUav.setupProperties.equipment.noEquipmentLabel" | transloco }}
                    </ng-container>
                </ng-container>
                <img imageSlot src="assets/images/empty-accessories.svg" alt="" />
            </dtm-ui-empty-state>
        </ng-template>
    </section>
</ng-container>
