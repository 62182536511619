<img src="/assets/images/no-access.svg" [alt]="'dtmWebAppLibShared.userContext.operatorContextNotAvailableTitle' | transloco" />
<div class="content">
    <h2 mat-dialog-title>{{ "dtmWebAppLibShared.userContext.operatorContextNotAvailableTitle" | transloco }}</h2>
    <p *ngIf="data?.operatorName as operatorName">
        {{ "dtmWebAppLibShared.userContext.operatorContextNotAvailableMessagePrefix" | transloco }}
        <strong>{{ operatorName }}</strong>
        {{ "dtmWebAppLibShared.userContext.operatorContextNotAvailableMessageSuffix" | transloco }}
    </p>
</div>

<mat-dialog-actions align="center">
    <button type="button" class="button-primary" [mat-dialog-close]="true" cdkFocusInitial>
        {{ "dtmWebAppLibShared.userContext.operatorContextNotAvailableButtonLabel" | transloco }}
    </button>
</mat-dialog-actions>
