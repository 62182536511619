import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { TextWithTooltip } from "@dtm-frontend/shared/map/geo-zones";
import { DEFAULT_LANG, I18nService, LOCALE_MAPPING } from "@dtm-frontend/shared/ui/i18n";
import { DEFAULT_DEBOUNCE_TIME, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { map } from "rxjs";
import {
    EvaluationIssueStatus,
    FlightRules,
    MissionPlanAnalysisEvaluationIssue,
    MissionPlanAnalysisIssue,
    MissionProcessingPhase,
    MissionProcessingPhaseExtended,
    Requirement,
} from "../../models";

interface RequirementsPanelComponentState {
    flightConditions: TextWithTooltip[];
    analysisIssues: MissionPlanAnalysisIssue[];
    evaluationIssues: MissionPlanAnalysisEvaluationIssue[];
    additionalRequirements: Requirement[];
    flightRules: FlightRules[];
    missionPhase: MissionProcessingPhaseExtended | undefined;
}

@Component({
    selector: "dtm-mission-requirements-panel",
    templateUrl: "./requirements-panel.component.html",
    styleUrls: ["./requirements-panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class RequirementsPanelComponent {
    @Input() public set analysisIssues(value: MissionPlanAnalysisIssue[] | undefined) {
        this.localStore.patchState({ analysisIssues: value ?? [] });
    }
    @Input() public set evaluationIssues(value: MissionPlanAnalysisEvaluationIssue[] | undefined) {
        this.localStore.patchState({ evaluationIssues: value ?? [] });
    }
    @Input() public set additionalRequirements(value: Requirement[]) {
        this.localStore.patchState({ additionalRequirements: value });
    }
    @Input() public set flightConditions(value: TextWithTooltip[] | undefined) {
        this.localStore.patchState({ flightConditions: value ?? [] });
    }
    @Input() public set flightRules(value: FlightRules[] | undefined) {
        this.localStore.patchState({ flightRules: value ?? [] });
    }
    @Input() public set missionPhase(value: MissionProcessingPhaseExtended | undefined) {
        this.localStore.patchState({ missionPhase: value });
    }
    protected readonly activeLanguageCode$ = this.i18nService.currentLanguage$.pipe(
        map((language) => LOCALE_MAPPING[language] ?? LOCALE_MAPPING[DEFAULT_LANG])
    );
    protected readonly analysisIssues$ = this.localStore.selectByKey("analysisIssues");
    protected readonly evaluationIssues$ = this.localStore
        .selectByKey("evaluationIssues")
        .pipe(map((issues) => issues.filter((issue) => issue.status !== EvaluationIssueStatus.Suppressed)));
    protected readonly additionalRequirements$ = this.localStore.selectByKey("additionalRequirements");
    protected readonly flightConditions$ = this.localStore.selectByKey("flightConditions");
    protected readonly flightRules$ = this.localStore
        .selectByKey("flightRules")
        .pipe(map((rules) => this.removeDuplicatesAndSortFlightRules(rules)));
    protected readonly isWaiting$ = this.localStore
        .selectByKey("missionPhase")
        .pipe(map((phase) => !phase || phase === MissionProcessingPhase.Waiting));
    protected readonly isRejected$ = this.localStore
        .selectByKey("missionPhase")
        .pipe(map((phase) => phase === MissionProcessingPhase.Rejected));

    protected readonly EvaluationIssueStatus = EvaluationIssueStatus;
    protected readonly MissionProcessingPhase = MissionProcessingPhase;

    private shouldSkipUpdate = false;

    constructor(
        private readonly localStore: LocalComponentStore<RequirementsPanelComponentState>,
        private readonly i18nService: I18nService
    ) {
        localStore.setState({
            flightConditions: [],
            analysisIssues: [],
            evaluationIssues: [],
            additionalRequirements: [],
            flightRules: [],
            missionPhase: undefined,
        });
    }

    // NOTE: toggleRulesVisibility and checkAndUpdateExpandFeatures methods manipulate DOM classes directly because we can't calculate
    // the height of the list properly when waiting for CD, and also CD also has some issues with updating view properly here
    protected toggleRulesVisibility(listElement: HTMLElement) {
        listElement.classList.toggle("expanded");
        // NOTE: we don't want to react to updates triggered by this method which can occur when scroll appears/disappears after change
        this.temporaryDisableMutationUpdates();
    }

    protected checkAndUpdateExpandFeatures(listElement: HTMLElement, expandButton: HTMLElement) {
        if (this.shouldSkipUpdate) {
            return;
        }
        listElement.classList.remove("expanded");

        if (listElement.clientHeight < listElement.scrollHeight) {
            expandButton.classList.remove("hidden");
        } else {
            expandButton.classList.add("hidden");
        }
    }

    private removeDuplicatesAndSortFlightRules(flightRules: FlightRules[]): FlightRules[] {
        return flightRules
            .reduce((uniqueRules, current) => {
                const isUnique = !uniqueRules.some((item) => item.translationId === current.translationId);

                return isUnique ? [...uniqueRules, current] : uniqueRules;
            }, [] as FlightRules[])
            .sort(this.sortRulesByPriority);
    }

    private sortRulesByPriority(left: FlightRules, right: FlightRules): number {
        const prioritiesRegexp = /\d+(?=\.)|(?<=\.)\d+$/g;

        const leftPriorities = left.translationId.match(prioritiesRegexp);
        const rightPriorities = right.translationId.match(prioritiesRegexp);

        if (leftPriorities && rightPriorities) {
            for (let i = 0; i < leftPriorities.length; i++) {
                if (leftPriorities[i] !== rightPriorities[i]) {
                    return +leftPriorities[i] - +rightPriorities[i];
                }
            }
        }

        return 0;
    }

    private temporaryDisableMutationUpdates() {
        this.shouldSkipUpdate = true;

        // NOTE:  DEFAULT_DEBOUNCE_TIME + 1 to make sure that update will be enabled after
        // the debounce time set in  dtmUiElementObserver directive (DEFAULT_DEBOUNCE_TIME by default)
        setTimeout(() => {
            this.shouldSkipUpdate = false;
        }, DEFAULT_DEBOUNCE_TIME + 1);
    }
}
