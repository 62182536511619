<ng-container *ngrxLet="{ isShown: isShown$ } as vm">
    <ac-layer #missionsLayer acFor="let entity of missionsAreasEntities$" [store]="true" [context]="this" [show]="vm.isShown">
        <ac-polygon-desc
            props="{
                hierarchy: entity.positions,
                material: entity.style.fill,
                zIndex: entity.zIndex
            }"
        />
        <ac-polyline-desc
            props="{
                positions: entity.positions.positions,
                width: entity.style.outlineWidth,
                material: entity.style.outline,
                zIndex: entity.zIndex
            }"
        />
    </ac-layer>
    <ac-layer #pinsLayer acFor="let entity of pinEntities$" [store]="true" [context]="this" [show]="vm.isShown">
        <ac-html-desc props="{ position: entity.position, show: entity.show }">
            <ng-template let-pin>
                <div class="mission-pin" *ngIf="vm.isShown">
                    <dtm-ui-icon name="map-pin-fill" class="pin-icon" />
                    <button type="button" class="button-icon map-icon" (click)="missionSelect.emit(pin.planId)">
                        <dtm-ui-icon name="road-map" />
                    </button>
                </div>
            </ng-template>
        </ac-html-desc>
    </ac-layer>
</ng-container>
