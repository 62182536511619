<mat-card *ngrxLet="{ setups: setups$ } as vm">
    <section class="header">
        <h4>{{ "dtmSharedUav.uavSetups.header" | transloco : { setupsAmount: vm.setups.length } }}</h4>
        <ng-content select="[headerSideSlot]"></ng-content>
    </section>

    <ng-container *ngrxLet="selectedSetup$ as selectedSetup">
        <section class="setups-list" *ngIf="vm.setups.length; else noSetupsTemplate">
            <dtm-ui-select-field [value]="selectedSetup" (selectionChange)="showSetupPreview($event.value)" [isClearable]="false">
                <label>{{ "dtmSharedUav.uavSetups.setupFieldLabel" | transloco }}</label>
                <dtm-ui-select-option *ngFor="let setup of vm.setups" [value]="setup">
                    {{ setup.name }}
                </dtm-ui-select-option>
            </dtm-ui-select-field>
            <ng-content select="[setupsListSideSlot]"></ng-content>
        </section>

        <dtm-uav-lib-uav-setup-preview
            [setup]="selectedSetup"
            [trackers]="trackers$ | ngrxPush"
            [navigationAccuracyItems]="navigationAccuracyItems$ | ngrxPush"
            [isNarrowMode]="isNarrowMode$ | ngrxPush"
        >
            <ng-content select="[emptyEquipmentMessageSlot]" ngProjectAs="[emptyEquipmentMessageSlot]"></ng-content>
        </dtm-uav-lib-uav-setup-preview>

        <ng-template #noSetupsTemplate>
            <dtm-ui-no-results />
        </ng-template>
    </ng-container>
</mat-card>
