import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
    ContextOperator,
    GlobalFeatures,
    GlobalOperatorPermissions,
    OperatorType,
    reloadComponent,
    UIActions,
    UIState,
} from "@dtm-frontend/shared/ui";
import { ArrayUtils, ObjectUtils } from "@dtm-frontend/shared/utils";
import { Action, createSelector, Selector, State, StateContext, Store } from "@ngxs/store";
import { EMPTY, tap } from "rxjs";
import { catchError, finalize, map } from "rxjs/operators";
import {
    GlobalCapabilitiesError,
    GlobalCapabilitiesPilot,
    GlobalCapabilitiesUser,
    OPERATOR_CONTEXT_PARAM,
} from "../models/operator-context.models";
import { GlobalCapabilitiesApiService } from "../services/user-data-api.service";
import { OperatorContextActions } from "./operator-context.actions";

export interface OperatorContextStateModel {
    isProcessing: boolean | undefined;
    selectedContext: ContextOperator | undefined;
    operators: { [key: string]: ContextOperator };
    operatorContextError: GlobalCapabilitiesError | undefined;
    pilot: GlobalCapabilitiesPilot | undefined;
    isAllowedToRegisterOperator: boolean;
    isAllowedToRegisterPilot: boolean;
    user: GlobalCapabilitiesUser | undefined;
    elearningPortalUrl: string | undefined;
    isRequiredToAcceptTermsOfService: boolean;
    acceptTermsOfServiceError: GlobalCapabilitiesError | undefined;
    features: GlobalFeatures[];
}

const defaultState: OperatorContextStateModel = {
    selectedContext: undefined,
    operators: {},
    isProcessing: undefined,
    operatorContextError: undefined,
    pilot: undefined,
    isAllowedToRegisterOperator: false,
    isAllowedToRegisterPilot: false,
    user: undefined,
    elearningPortalUrl: undefined,
    isRequiredToAcceptTermsOfService: false,
    acceptTermsOfServiceError: undefined,
    features: [],
};

@State<OperatorContextStateModel>({
    name: "operatorContext",
    defaults: defaultState,
})
@Injectable()
export class OperatorContextState {
    constructor(
        private readonly globalCapabilitiesApiService: GlobalCapabilitiesApiService,
        private readonly store: Store,
        private readonly router: Router
    ) {}

    @Selector([OperatorContextState.selectedContextId])
    public static selectedContext(state: OperatorContextStateModel, id: string): ContextOperator | undefined {
        return state.operators[id];
    }

    @Selector()
    public static selectedContextId(state: OperatorContextStateModel): string | undefined {
        return state.selectedContext?.id;
    }

    @Selector()
    public static operatorContextError(state: OperatorContextStateModel): GlobalCapabilitiesError | undefined {
        return state.operatorContextError;
    }

    @Selector()
    public static selectedContextType(state: OperatorContextStateModel): OperatorType | undefined {
        return state.selectedContext?.type;
    }

    @Selector()
    public static isProcessing(state: OperatorContextStateModel): boolean | undefined {
        return state.isProcessing;
    }

    @Selector()
    public static pilot(state: OperatorContextStateModel): GlobalCapabilitiesPilot | undefined {
        return state.pilot;
    }

    @Selector()
    public static operators(state: OperatorContextStateModel): { [key: string]: ContextOperator } {
        return state.operators;
    }

    @Selector()
    public static isAllowedToRegisterOperator(state: OperatorContextStateModel): boolean {
        return state.isAllowedToRegisterOperator;
    }

    @Selector()
    public static isAllowedToRegisterPilot(state: OperatorContextStateModel): boolean {
        return state.isAllowedToRegisterPilot;
    }

    @Selector()
    public static isRequiredToAcceptTermsOfService(state: OperatorContextStateModel): boolean {
        return state.isRequiredToAcceptTermsOfService;
    }

    @Selector()
    public static elearningPortalUrl(state: OperatorContextStateModel): string | undefined {
        return state.elearningPortalUrl;
    }

    @Selector()
    public static acceptTermsOfServiceError(state: OperatorContextStateModel): GlobalCapabilitiesError | undefined {
        return state.acceptTermsOfServiceError;
    }

    @Selector()
    public static features(state: OperatorContextStateModel): GlobalFeatures[] {
        return state.features;
    }

    public static isFeatureAvailable(feature: GlobalFeatures) {
        return createSelector([OperatorContextState], (state: OperatorContextStateModel) => {
            const selectedContextId = state.selectedContext?.id;
            if (!selectedContextId) {
                return state.features.includes(feature);
            }

            return state.operators[selectedContextId].features.includes(feature);
        });
    }

    public static isPermitted(permission: GlobalOperatorPermissions) {
        return createSelector([OperatorContextState], (state: OperatorContextStateModel) => {
            const selectedContext = state.selectedContext;
            if (!selectedContext) {
                return undefined;
            }

            if (selectedContext.type === OperatorType.Personal) {
                return true;
            }

            return state.operators[selectedContext.id].permissions.includes(permission);
        });
    }

    @Selector([OperatorContextState.personalOperator])
    public static hasPersonalOperator(state: OperatorContextStateModel, operator: ContextOperator | undefined): boolean {
        return !!operator;
    }

    @Selector([OperatorContextState.operatorsSortedByName])
    public static personalOperator(state: OperatorContextState, operators: ContextOperator[]): ContextOperator | undefined {
        return operators.find((operator) => operator.type === "PERSONAL");
    }

    @Selector([OperatorContextState.operatorEntities])
    public static operatorsSortedByName(
        state: OperatorContextStateModel,
        operators: { [key: string]: ContextOperator }
    ): ContextOperator[] {
        return Object.entries(operators)
            .map(([, operator]) => operator)
            .sort((left, right) => new Intl.Collator().compare(left.name ?? "", right.name ?? ""));
    }

    @Selector()
    public static operatorEntities(state: OperatorContextStateModel): { [key: string]: ContextOperator } {
        return state.operators;
    }

    @Selector()
    public static user(state: OperatorContextStateModel): GlobalCapabilitiesUser | undefined {
        return state.user;
    }

    @Selector()
    public static isRequiredRegisterNationalNodeAuthenticatedUser(state: OperatorContextStateModel): boolean | undefined {
        return state.user?.isRequiredRegisterNationalNodeAuthenticatedUser;
    }

    @Selector([OperatorContextState.selectedContextId])
    public static selectedOperatorContextId(state: OperatorContextStateModel, id: string): string {
        return state.operators[id].id;
    }

    public static operator(operatorId: string) {
        return createSelector(
            [OperatorContextState],
            (state: OperatorContextStateModel): ContextOperator | undefined => state.operators[operatorId]
        );
    }

    @Action(OperatorContextActions.GetGlobalCapabilities)
    public getGlobalCapabilities(context: StateContext<OperatorContextStateModel>) {
        context.patchState({ isProcessing: true, operatorContextError: undefined });

        return this.globalCapabilitiesApiService.getUserCapabilities().pipe(
            tap((response) => {
                if (response.languageTag !== this.store.selectSnapshot(UIState.activeLanguage)) {
                    this.store.dispatch(new UIActions.SetActiveLanguage(response.languageTag));
                }
            }),
            map((result) => {
                const { operators: previousOperators, pilot } = context.getState();
                const listOfOperators = ArrayUtils.toObject(result.operators, "id");
                const operators = ObjectUtils.mapObjectValues(listOfOperators, (id, operator) => ({
                    ...previousOperators[id],
                    ...operator,
                }));

                let selectedContext =
                    context.getState().selectedContext ??
                    result.operators.find((operator) => operator.id === localStorage.getItem(OPERATOR_CONTEXT_PARAM)) ??
                    result.operators[0];

                if (!Object.keys(operators).find((key) => key === selectedContext.id)) {
                    selectedContext = result.operators[0];
                }

                context.patchState({
                    selectedContext,
                    operators,
                    pilot: pilot ?? result.pilot,
                    isAllowedToRegisterOperator: result.isAllowedToRegisterOperator,
                    isAllowedToRegisterPilot: result.isAllowedToRegisterPilot,
                    user: result.user,
                    elearningPortalUrl: result.elearningPortalUrl,
                    isRequiredToAcceptTermsOfService: result.isRequiredToAcceptTermsOfService,
                    features: result.features,
                });
            }),
            catchError((error) => {
                context.patchState({ operatorContextError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(OperatorContextActions.SelectContext)
    public selectContext(context: StateContext<OperatorContextStateModel>, action: OperatorContextActions.SelectContext) {
        if (action.operator.id === context.getState().selectedContext?.id) {
            return;
        }

        context.patchState({ selectedContext: action.operator });
        const shouldSkipReloadOnOperatorChange = this.router.routerState.snapshot.root.firstChild?.data.shouldSkipReloadOnOperatorChange;
        if (!shouldSkipReloadOnOperatorChange) {
            reloadComponent(this.router);
        }
    }

    @Action(OperatorContextActions.AcceptTermsOfService)
    public acceptTermsOfService(context: StateContext<OperatorContextStateModel>, action: OperatorContextActions.AcceptTermsOfService) {
        context.patchState({ isProcessing: true });

        return this.globalCapabilitiesApiService.acceptTermsOfService(action.userId).pipe(
            catchError((error) => {
                context.patchState({ acceptTermsOfServiceError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }
}
