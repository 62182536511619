<dtm-mission-mission-preview
    [isProcessing]="isProcessing$ | ngrxPush"
    [missionData]="mission$ | ngrxPush"
    [route]="route$ | ngrxPush"
    [flightPurposes]="flightPurposes$ | ngrxPush"
    [analysisStatus]="analysisStatus$ | ngrxPush"
    [caaPermitData]="caaPermitData$ | ngrxPush"
    [nearbyMissionsRouteData]="nearbyMissionsRouteData$ | ngrxPush"
    [isTrafficAnalysisProcessing]="isTrafficAnalysisProcessing$ | ngrxPush"
    [isFlightRequirementsProcessing]="isFlightRequirementsProcessing$ | ngrxPush"
    [collisionZones]="collisionZones$ | ngrxPush"
    [soraSettings]="soraSettings$ | ngrxPush"
    [selectedZoneId]="selectedZoneId$ | ngrxPush"
    (zoneSelect)="selectZone($event)"
>
    <div class="header" headerSlot>
        <button type="button" class="button-secondary" routerLink="..">
            <dtm-ui-icon name="arrow-go-back" />
            {{ "dtmSharedMissionSearch.missionPreview.goBackToListButtonLabel" | transloco }}
        </button>
    </div>
</dtm-mission-mission-preview>
