import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface MissionMessagesComponentState {
    messages: Message[];
}

export interface Message {
    text: string;
    time?: Date;
}

@Component({
    selector: "dtm-mission-mission-messages",
    templateUrl: "./mission-messages.component.html",
    styleUrls: ["./mission-messages.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionMessagesComponent {
    @Input()
    public set messages(value: Message[] | undefined) {
        this.localStore.patchState({ messages: value ?? [] });
    }

    public readonly messages$ = this.localStore.selectByKey("messages");

    constructor(private readonly localStore: LocalComponentStore<MissionMessagesComponentState>) {
        localStore.setState({ messages: [] });
    }
}
