<ng-container *ngrxLet="{ uav: uav$, isShortMode: isShortMode$ } as vm">
    <div class="image">
        <img *ngIf="vm.uav.imageUrl" [dtmUiImgSecuredSrc]="vm.uav.imageUrl" [alt]="vm.uav.manufacturerName + ' ' + vm.uav.modelName" />
        <img *ngIf="!vm.uav.imageUrl" src="assets/images/uav-no-photo.svg" alt="" />
    </div>
    <div class="name">{{ vm.uav.name }}</div>
    <div class="chips-list">
        <span *ngIf="vm.uav.isCustom" class="chip light">
            <dtm-ui-icon name="pencil-ruler" />
            {{ "dtmSharedUav.uavInfo.customUavLabel" | transloco }}
        </span>
        <span *ngIf="vm.uav.isSwarm" class="chip light">
            <dtm-ui-icon name="drone-swarm" />
            {{ "dtmSharedUav.uavInfo.swarmLabel" | transloco : { amount: vm.uav.serialNumbers.length } }}
        </span>
    </div>
    <ng-container *ngIf="!vm.isShortMode">
        <div class="actions">
            <ng-content select="[actionsSlot]"></ng-content>
        </div>
        <div class="info">
            <dtm-ui-label-value
                class="overflow-wrap"
                [label]="'dtmSharedUav.uavInfo.manufacturerAndModelLabel' | transloco"
                [value]="vm.uav.manufacturerName + ' ' + vm.uav.modelName"
            />
            <dtm-ui-label-value
                [label]="'dtmSharedUav.uavInfo.uavTypeLabel' | transloco"
                [value]="'dtmSharedUav.uavInfo.uavTypeValue' | transloco : { value: vm.uav.modelType }"
            />
            <dtm-ui-label-value [label]="'dtmSharedUav.uavInfo.ceCompliantLabel' | transloco">
                <div class="value-with-icon">
                    <dtm-ui-icon [name]="vm.uav.isCeCompliant ? 'checkbox-circle' : 'close-circle'" />
                    {{ "dtmSharedUav.uavInfo.booleanValue" | transloco : { value: vm.uav.isCeCompliant } }}
                </div>
            </dtm-ui-label-value>
            <dtm-ui-label-value
                class="overflow-wrap"
                *ngIf="!vm.uav.isSwarm"
                [label]="'dtmSharedUav.uavInfo.serialNumberLabel' | transloco"
                [value]="vm.uav.serialNumbers[0]"
                [canCopy]="true"
            />
            <dtm-ui-label-value *ngIf="vm.uav.isSwarm" [label]="'dtmSharedUav.uavInfo.serialNumbersLabel' | transloco">
                <a class="serial-numbers-button" (click)="showSerialNumbers(vm.uav)">
                    {{ "dtmSharedUav.uavInfo.showSerialNumbersButtonLabel" | transloco }}
                </a>
            </dtm-ui-label-value>
            <dtm-ui-label-value
                *ngIf="vm.uav.isShared"
                [label]="'dtmSharedUav.uavInfo.ownerLabel' | transloco"
                [value]="vm.uav.ownerName"
            />
            <dtm-ui-label-value [label]="'dtmSharedUav.uavInfo.uavClassLabel' | transloco">
                <ng-container *ngIf="!vm.uav.uavClasses.length">{{ "dtmSharedUav.uavInfo.noUavClassesLabel" | transloco }}</ng-container>
                <span class="uav-class" *ngFor="let uavClass of vm.uav.uavClasses">{{
                    "dtmSharedUav.uavInfo.uavClassValue" | transloco : { value: uavClass }
                }}</span>
            </dtm-ui-label-value>
        </div>
    </ng-container>
    <div class="info-short" *ngIf="vm.isShortMode">
        ({{ vm.uav.manufacturerName + " " + vm.uav.modelName }},
        {{ "dtmSharedUav.uavInfo.uavTypeValue" | transloco : { value: vm.uav.modelType } }},
        <ng-container *ngIf="!vm.uav.isSwarm">{{ vm.uav.serialNumbers[0] }})</ng-container>
        <ng-container *ngIf="vm.uav.isSwarm">
            <a class="serial-numbers-button" (click)="showSerialNumbers(vm.uav)">
                {{ "dtmSharedUav.uavInfo.showSerialNumbersButtonLabel" | transloco }} </a
            >)
        </ng-container>
    </div>
</ng-container>
