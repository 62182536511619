import { Injectable } from "@angular/core";
import { NavigationAccuracy, Tracker, Uav } from "@dtm-frontend/shared/uav";
import { Page } from "@dtm-frontend/shared/ui";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY } from "rxjs";
import { catchError, finalize, tap } from "rxjs/operators";
import { UavApiService } from "../services/uav-api.service";
import { UavError, UavListFilterParams, UavListItem } from "../services/uav.models";
import { UavActions } from "./uav.actions";

export interface UavStateModel {
    isProcessing: boolean;
    uavsError: UavError | undefined;
    uavs: UavListItem[] | undefined;
    uavListParams: UavListFilterParams | undefined;
    uavsPagination: Page | undefined;
    capabilitiesError: UavError | undefined;
    uavError: UavError | undefined;
    uavDetails: Uav | undefined;
    trackers: Tracker[] | undefined;
    navigationAccuracyItems: NavigationAccuracy[] | undefined;
}

const defaultState: UavStateModel = {
    isProcessing: false,
    uavsError: undefined,
    uavs: undefined,
    uavListParams: undefined,
    uavsPagination: undefined,
    capabilitiesError: undefined,
    uavError: undefined,
    uavDetails: undefined,
    trackers: undefined,
    navigationAccuracyItems: undefined,
};

@State<UavStateModel>({
    name: "uav",
    defaults: defaultState,
})
@Injectable()
export class UavState {
    constructor(private readonly uavApiService: UavApiService) {
        if (uavApiService === undefined) {
            throw new Error("Initialize UavModule with .forRoot()");
        }
    }

    @Selector()
    public static isProcessing(state: UavStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static uavsError(state: UavStateModel): UavError | undefined {
        return state.uavsError;
    }

    @Selector()
    public static uavs(state: UavStateModel): UavListItem[] | undefined {
        return state.uavs;
    }

    @Selector()
    public static uavListParams(state: UavStateModel): UavListFilterParams | undefined {
        return state.uavListParams;
    }

    @Selector()
    public static uavsPagination(state: UavStateModel): Page | undefined {
        return state.uavsPagination;
    }

    @Selector()
    public static uavDetails(state: UavStateModel): Uav | undefined {
        return state.uavDetails;
    }

    @Selector()
    public static uavError(state: UavStateModel): UavError | undefined {
        return state.uavError;
    }

    @Selector()
    public static capabilitiesError(state: UavStateModel): UavError | undefined {
        return state.capabilitiesError;
    }

    @Selector()
    public static trackers(state: UavStateModel): Tracker[] | undefined {
        return state.trackers;
    }

    @Selector()
    public static navigationAccuracyItems(state: UavStateModel): NavigationAccuracy[] {
        return state.navigationAccuracyItems ?? [];
    }

    @Action(UavActions.GetUavs, { cancelUncompleted: true })
    public getUavs(context: StateContext<UavStateModel>, action: UavActions.GetUavs) {
        context.patchState({
            isProcessing: true,
        });

        return this.uavApiService.getUavs(action.params).pipe(
            tap((result) =>
                context.patchState({
                    uavs: result.content,
                    uavsError: undefined,
                    uavsPagination: result.pagination,
                })
            ),
            catchError((error) => {
                context.patchState({ uavsError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(UavActions.SetUavListParams)
    public setOperatorListParams(context: StateContext<UavStateModel>, action: UavActions.SetUavListParams) {
        context.patchState({ uavListParams: action.filtersQuery });
    }

    @Action(UavActions.GetCapabilities, { cancelUncompleted: true })
    public getCapabilities(context: StateContext<UavStateModel>) {
        context.patchState({
            isProcessing: true,
        });

        return this.uavApiService.getCapabilities().pipe(
            tap((result) =>
                context.patchState({
                    trackers: result.trackers,
                    navigationAccuracyItems: result.navigationAccuracyItems,
                    capabilitiesError: undefined,
                })
            ),
            catchError((error) => {
                context.patchState({ capabilitiesError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(UavActions.GetUav, { cancelUncompleted: true })
    public getUav(context: StateContext<UavStateModel>, action: UavActions.GetUav) {
        context.patchState({
            isProcessing: true,
        });

        return this.uavApiService.getUav(action.uavId).pipe(
            tap((result) =>
                context.patchState({
                    uavDetails: result,
                    uavError: undefined,
                })
            ),
            catchError((error) => {
                context.patchState({ uavError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }
}
