import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "linkify",
})
export class LinkifyPipe implements PipeTransform {
    public transform(value: string | null | undefined, target = "_blank"): string {
        if (!value) {
            return "";
        }

        // Regex to find existing <a> tags
        const anchorTagPattern = /<a\b[^>]*?href=["']([^"']+)["'][^>]*?>(.*?)<\/a>/gi;

        // Regex to find plain URLs
        const urlPattern = /(?:https?:\/\/|www\.)[^\s/$.?#].[^\s]*|\b[a-z0-9.-]+\.[a-z]{2,}\/?[^\s]*/gi;

        // Store existing <a> tags in a temporary map to protect them
        const anchors: string[] = [];
        value = value.replace(anchorTagPattern, (match, href, description) => {
            const placeholder = `@@ANCHOR_${anchors.length}@@`; // Unique placeholder
            anchors.push(`<a href="${href}" target="${target}">${description}</a>`);

            return placeholder;
        });

        // Add links to plain URLs
        value = value.replace(urlPattern, (url) => {
            let href = url;
            if (!url.startsWith("http")) {
                href = "http://" + url;
            }

            return `<a href="${href}" target="${target}">${url}</a>`;
        });

        // Restore processed <a> tags from placeholders
        anchors.forEach((anchor, index) => {
            const placeholder = `@@ANCHOR_${index}@@`;
            value = value?.replace(placeholder, anchor);
        });

        return value;
    }
}
