import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { UavCardComponent } from "./components/uav-card/uav-card.component";
import { UavContainerComponent } from "./components/uav-container/uav-container.component";
import { UAV_ID_ROUTE_PARAM_NAME, UavResolver } from "./services/uav.resolvers";

const routes: Routes = [
    {
        path: "uav/list",
        component: UavContainerComponent,
        canActivate: [DtmRoleGuard],
        data: {
            titleTranslationKey: "dtmAdminLibUav.routeTitles.uavList",
        },
    },
    {
        path: `uav/:${UAV_ID_ROUTE_PARAM_NAME}`,
        component: UavCardComponent,
        canActivate: [DtmRoleGuard],
        resolve: {
            uavId: UavResolver,
        },
        data: {
            titleTranslationKey: "dtmAdminLibUav.routeTitles.uavCard",
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [UavResolver],
})
export class UavRoutingModule {}
