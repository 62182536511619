import { UavListFilterParams } from "../services/uav.models";

export namespace UavActions {
    export class GetUavs {
        public static readonly type = "[UAV] Get uavs list";

        constructor(public params: UavListFilterParams) {}
    }

    export class SetUavListParams {
        public static readonly type = "[UAV] Set uavs list query params";

        constructor(public filtersQuery: UavListFilterParams) {}
    }

    export class GetCapabilities {
        public static readonly type = "[UAV] Get uav capabilities";
    }

    export class GetUav {
        public static readonly type = "[UAV] Get uav details";

        constructor(public uavId: string) {}
    }
}
