/* eslint-disable max-len */

import { createDynamicEnvironment } from "@dtm-frontend/shared/utils";
import type DynamicConfiguation from "../assets/dynamic.config.json";
import { CaaAdminEnvironment } from "./environment.model";

export const dynamicEnvironment = createDynamicEnvironment<CaaAdminEnvironment, typeof DynamicConfiguation>(
    fetch("../assets/dynamic.config.json").then((config) => config.json()),
    ({ apiDomain, keycloakConfig, sharedMapEndpoints, uavAppUrl }) => {
        const apiUrl = `https://${apiDomain}/api`;
        const webSocketApiUrl = `wss://${apiDomain}/api/ws`;

        return {
            production: false,
            name: "dev",
            bearerExcludedUrls: [],
            webSocketEndpoint: webSocketApiUrl,
            keycloakConfig,
            uavAppUrl,
            operatorEndpoints: {
                getCapabilities: `${apiUrl}/caa/operator/capabilities`,
                getOperatorList: `${apiUrl}/caa/operator/operators`,
                changeOperatorStatus: `${apiUrl}/caa/operator/operators/{{id}}/status`,
                getAvailableOperationScenarios: `${apiUrl}/caa/operator/operators/{{id}}/available-operation-scenarios`,
                addOperationalAuthorization: `${apiUrl}/caa/operator/operators/{{id}}/operational-authorizations`,
                manageOperationalAuthorization: `${apiUrl}/caa/operator/operators/{{id}}/operational-authorizations/{{operationalAuthorizationId}}`,
                getPersonalOperatorDetails: `${apiUrl}/caa/operator/operators/{{id}}/personal-profile`,
                getEnterpriseOperatorDetails: `${apiUrl}/caa/operator/operators/{{id}}/enterprise-profile`,
                getAssociationOperatorDetails: `${apiUrl}/caa/operator/operators/{{id}}/association-profile`,
                getAttorneyPowerDocument: `${apiUrl}/caa/operator/operators/{{id}}/attorney-powers/documents/{{documentId}}`,
                getAdministrativeFeeDocument: `${apiUrl}/caa/operator/attorney-power-administrative-fee/documents/{{documentId}}`,
                updateAttorneyPower: `${apiUrl}/caa/operator/operators/{{id}}/attorney-powers/{{attorneyPowerId}}/status`,
                updateAttorneyPowerPermissions: `${apiUrl}/caa/operator/operators/{{id}}/attorney-powers/{{attorneyPowerId}}/permissions`,
                addOtherInformation: `${apiUrl}/caa/operator/operators/{{id}}/other-information`,
                manageOtherInformation: `${apiUrl}/caa/operator/operators/{{operatorId}}/other-information/{{otherInformationId}}`,
                manageOperator: `${apiUrl}/caa/operator/operators/{{operatorId}}`,
                getForeignCompetencyDocument: `${apiUrl}/caa/operator/pilots/{{pilotId}}/competency-confirmation-files/{{fileId}}`,
                updateForeignCompetencyStatus: `${apiUrl}/caa/operator/pilots/{{pilotId}}/competency-confirmations/{{competencyId}}/status`,
            },
            operationScenarioStatementsEndpoints: {
                operationScenarioStatementsList: `${apiUrl}/caa/operational-authorizations/operation-scenarios-statements`,
                downloadAdministrativeFeeFile: `${apiUrl}/caa/operational-authorizations/operation-scenarios-statements/attorney-power-administrative-fee-documents/{{feeDocumentId}}`,
                downloadStatementConfirmationFile: `${apiUrl}/caa/operational-authorizations/operators/{{operatorId}}/operation-scenarios-statements/{{statementId}}/signed-document`,
            },
            sharedMapEndpoints,
            translationEndpoints: {
                getSystemTranslations: `${apiUrl}/system/translations/{{locale}}`,
            },
            conversationEndpoints: {
                getCapabilities: `${apiUrl}/caa/conversations/caa-threads-capabilities`,
                getOrAddThreads: `${apiUrl}/caa/conversations/threads`,
                getOperatorCapabilities: `${apiUrl}/caa/conversations/{{operatorId}}/caa-messages-capabilities`,
                getOrAddMessages: `${apiUrl}/caa/conversations/threads/{{threadId}}/messages`,
                uploadFile: `${apiUrl}/caa/conversations/{{operatorId}}/attachments`,
                downloadFile: `${apiUrl}/caa/conversations/{{operatorId}}/attachments/{{fileId}}`,
                downloadCompressedFiles: `${apiUrl}/caa/conversations/{{operatorId}}/attachments/compressed`,
                manageThread: `${apiUrl}/caa/conversations/threads/{{threadId}}`,
                changeThreadAssignment: `${apiUrl}/caa/conversations/threads/{{threadId}}/assignee`,
                changeThreadCategory: `${apiUrl}/caa/conversations/threads/{{threadId}}/category/{{categoryCode}}`,
            },
            versionDataEndpoints: {
                getVersionDetails: `${apiUrl}/system/service-info`,
            },
            notificationEndpoints: {
                notificationsList: `${apiUrl}/notifications`,
                notificationsCount: `${apiUrl}/notifications/counter`,
                getNotifications: "/websocket/direct/{{userId}}/notifications",
                markNotificationAsRead: `${apiUrl}/notifications/{{id}}/read`,
                markAllAsRead: `${apiUrl}/notifications/read`,
            },
            dashboardEndpoints: {
                getDashboardDetails: `${apiUrl}/caa/dashboard `,
            },
            pilotEndpoints: {
                addPilotCompetency: `${apiUrl}/operators/pilots/{{pilotId}}/competencies`,
                managePilotCompetency: `${apiUrl}/operators/pilots/{{pilotId}}/competencies/{{competencyId}}`,
                manageAllCompetencies: ` ${apiUrl}/operators/pilots/{{pilotId}}/competencies/status`,
            },
            userProfileEndpoints: {
                getUserProfile: `${apiUrl}/caa/user-profile/{{userId}}`,
                getProfileCapabilities: `${apiUrl}/caa/user-profile/{{userId}}/capabilities`,
                getProfileAvatar: `${apiUrl}/caa/user-profile/{{userId}}/avatar`,
                saveEmailAddress: `${apiUrl}/caa/user-profile/{{userId}}/email`,
                savePhoneNumber: `${apiUrl}/caa/user-profile/{{userId}}/phone-number`,
                saveConversationCategories: `${apiUrl}/caa/user-profile/{{userId}}/conversation-categories`,
                savePersonalData: `${apiUrl}/caa/user-profile/{{userId}}/personal-data`,
                updateProfileLanguage: `${apiUrl}/caa/user-profile/{{userId}}/language-tag`,
                resetUserPassword: `${apiUrl}/caa/user-profile/{{userId}}/reset-password`,
                manageProfileAvatar: `${apiUrl}/caa/user-profile/{{userId}}/avatar`,
            },
            adminUsersEndpoints: {
                getOrAddUsers: `${apiUrl}/caa/caa-users`,
                manageUser: `${apiUrl}/caa/caa-users/{{adminUserId}}`,
                resetPassword: `${apiUrl}/caa/caa-users/{{adminUserId}}/reset-password`,
            },
            permitsEndpoints: {
                managePermits: `${apiUrl}/caa/permit/specific-permits`,
                updateSpecificPermit: `${apiUrl}/caa/permit/specific-permits/{{permitId}}`,
                manageAssociationPermits: `${apiUrl}/caa/permit/association-specific-permits`,
                manageCrossBorderPermits: `${apiUrl}/caa/permit/cross-border-specific-permits`,
                updateCrossBorderPermit: `${apiUrl}/caa/permit/cross-border-specific-permits/{{permitId}}`,
                getOperatorList: `${apiUrl}/caa/permit/operators`,
                getAssociationOperatorList: `${apiUrl}/caa/permit/association-operators`,
                getSpecificPermitsCapabilities: `${apiUrl}/caa/permit/specific-permits/capabilities`,
                updateSpecificPermitStatus: `${apiUrl}/caa/permit/specific-permits/{{id}}/status`,
                getPermitDetails: `${apiUrl}/caa/permit/specific-permits/{{id}}`,
                manageAssociationPermitDetails: `${apiUrl}/caa/permit/association-specific-permits/{{id}}`,
                uploadKmlFile: `${apiUrl}/caa/permit/specific-permits/kml-files`,
                downloadKmlFile: `${apiUrl}/caa/permit/specific-permits/kml-files/{{kmlFileId}}`,
                downloadKmlLocationFile: `${apiUrl}/caa/permit/specific-permits/kml-files/{{kmlFileId}}/location`,
                getCrossBorderPermitDetails: `${apiUrl}/caa/permit/cross-border-specific-permits/{{id}}`,
            },
            globalCapabilitiesEndpoints: {
                getGlobalCapabilities: `${apiUrl}/caa/capabilities`,
            },
            utmUsersEndpoints: {
                getUserList: `${apiUrl}/caa/utm-users`,
                getUser: `${apiUrl}/caa/utm-users/{{userId}}/profile`,
                updateUserDetails: `${apiUrl}/caa/utm-users/{{userId}}`,
                updateUserLegalGuardianDetails: `${apiUrl}/caa/utm-users/{{userId}}/legal-guardian`,
                deleteUserLegalGuardian: `${apiUrl}/caa/utm-users/{{userId}}/legal-guardian`,
                changeUserIdentityDocumentStatus: `${apiUrl}/caa/utm-users/{{userId}}/identity-documents/{{identityDocumentId}}/status`,
                downloadIdentityDocument: `${apiUrl}/caa/utm-users/{{userId}}/identity-documents/{{identityDocumentId}}`,
                getLegalGuardianSignedDocument: `${apiUrl}/caa/utm-users/{{userId}}/legal-guardian/signed-document`,
                requestForDocumentUpdate: `${apiUrl}/caa/utm-users/{{userId}}/identity-document-completion-request`,
                requestForLegalGuardianUpdate: `${apiUrl}/caa/utm-users/{{userId}}/legal-guardian-completion-request`,
            },
            centersEndpoints: {
                addAndGetExaminationCenter: `${apiUrl}/caa/education/examining-centers`,
                editAndDeleteExaminationCenter: `${apiUrl}/caa/education/examining-centers/{{id}}`,
                getCapabilities: `${apiUrl}/caa/education/capabilities`,
                getOperatorsList: `${apiUrl}/caa/education/operators`,
                getOperatorDetails: `${apiUrl}/caa/education/operator/{{id}}`,
                manageTrainingCenters: `${apiUrl}/caa/education/training-operators`,
                manageTrainingCenter: `${apiUrl}/caa/education/training-operators/{{id}}`,
            },
            examTrainingResultsEndpoints: {
                getExamResultsList: `${apiUrl}/caa/education/exam-confirmations`,
                getTrainingResultsList: `${apiUrl}/caa/education/training-confirmations`,
                manageExamTrainingResultsReportDetails: `${apiUrl}/caa/education/reports/{{reportId}}/results`,
                uploadExamTrainingResultsReportFile: `${apiUrl}/caa/education/reports`,
                downloadExamTrainingResultsReportFile: `${apiUrl}/caa/education/reports/{{fileId}}`,
                manageExamConfirmation: `${apiUrl}/caa/education/exam-confirmations/{{reportId}}`,
                manageTrainingConfirmation: `${apiUrl}/caa/education/training-confirmations/{{reportId}}`,
            },
            azureMapsSubscriptionKey: "HS9JYfhTrM0Ud2smxePEEpkueCl81_SAD2T-VU-ZNFk",
            auditEndpoints: {
                getChangeLog: `${apiUrl}/caa/audit/audit-logs`,
            },
            missionSearchEndpoints: {
                getPlans: `${apiUrl}/mission-browsing/plans`,
                getPlanData: `${apiUrl}/mission-browsing/{{planId}}`,
                getPlanVerification: `${apiUrl}/mission-browsing/{{planId}}/plan-verification`,
                getPlanCaaPermitDetails: `${apiUrl}/mission-browsing/{{planId}}/caa-permit-details`,
                getPlanItinerary: `${apiUrl}/mission-browsing/{{planId}}/itinerary`,
                getPublicDetails: `${apiUrl}/mission-browsing/{{planId}}/public-details`,
                getRoute: `${apiUrl}/mission-browsing/routes/{{routeId}}`,
                getPlansGeometries: `${apiUrl}/mission-browsing/geometry-search`,
            },
            geoZonesEndpoints: {
                searchAirspaceElements: `${apiUrl}/geospace/airspace-elements/search`,
                wsAirspaceTopicName: "/websocket/topic/geospace/airspace/all",
            },
            weatherEndpoints: {
                getWeatherConditions: `${apiUrl}/weather-forecast/detailed/{{zoneDesignator}}/range`,
                getMissionPlanWeather: `${apiUrl}/weather-forecast/range`,
                getSupportedWeatherZones: `${apiUrl}/weather-forecast/detailed`,
            },
            uavEndpoints: {
                getUavList: `${apiUrl}/caa/uavs`,
                getCapabilities: `${apiUrl}/caa/uavs/capabilities`,
                getUavDetails: `${apiUrl}/caa/uavs/{{uavId}}`,
                getUavModelPhoto: `${apiUrl}/caa/uavs/models/photos/{{photoId}}`,
                getUavSetupDocument: `${apiUrl}/caa/uavs/setups/documents/{{documentId}}`,
                getUavModelDocument: `${apiUrl}/caa/uavs/models/documents/{{documentId}}`,
            },
        };
    }
);
