import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import {
    AfterContentInit,
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    ElementRef,
    Input,
    ViewChild,
} from "@angular/core";
import { AbstractControl, FormControlStatus, NgControl } from "@angular/forms";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatestWith } from "rxjs";
import { first, map, startWith } from "rxjs/operators";
import { FormFieldComponent } from "../form-field/form-field.component";
import { DisablableInputFieldDirective } from "./disablable-input-field.directive";

interface InputFieldComponentState {
    isClearButtonVisible: boolean;
    isClearable: boolean;
    isDisabled: boolean;
    isActivated: boolean;
    hasErrors: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-ui-input-field",
    templateUrl: "./input-field.component.html",
    styleUrls: ["./input-field.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class InputFieldComponent implements AfterContentInit, AfterViewInit {
    @ContentChild(NgControl) private input!: NgControl;
    @ContentChild(DisablableInputFieldDirective) private disablableInput!: DisablableInputFieldDirective;
    @ViewChild(FormFieldComponent, { read: ElementRef }) private formField: ElementRef<HTMLHtmlElement> | undefined;

    @Input() public set isClearable(value: BooleanInput) {
        this.localStore.patchState({ isClearable: coerceBooleanProperty(value) });
    }
    @Input() public set hasErrors(value: boolean | undefined) {
        this.localStore.patchState({ hasErrors: !!value });
    }

    protected isClearButtonVisible$ = this.localStore.selectByKey("isClearable").pipe(
        combineLatestWith(this.localStore.selectByKey("isClearButtonVisible")),
        map(([isClearable, isClearButtonVisible]) => isClearable && isClearButtonVisible)
    );
    protected isDisabled$ = this.localStore.selectByKey("isDisabled");
    protected isActivated$ = this.localStore.selectByKey("isActivated");
    protected hasErrors$ = this.localStore.selectByKey("hasErrors");

    private get control(): AbstractControl | undefined {
        return this.input?.control ?? undefined;
    }

    constructor(private readonly localStore: LocalComponentStore<InputFieldComponentState>) {
        this.localStore.setState({
            isClearButtonVisible: false,
            isClearable: true,
            isDisabled: false,
            isActivated: false,
            hasErrors: false,
        });
    }

    public ngAfterViewInit(): void {
        this.createAriaLabelForInput();
    }

    public ngAfterContentInit() {
        if (!this.control) {
            if (this.disablableInput) {
                this.disablableInput.isDisabled$.pipe(untilDestroyed(this)).subscribe((isDisabled) => {
                    this.localStore.patchState({ isDisabled });
                });
            }

            return;
        }

        this.control.valueChanges.pipe(first(), untilDestroyed(this)).subscribe(() => {
            this.localStore.patchState({
                isActivated: true,
            });
        });
        this.control.valueChanges.pipe(startWith(this.control.value), untilDestroyed(this)).subscribe((value) => {
            this.localStore.patchState({
                isClearButtonVisible: !this.isEmptyValue(value),
            });
        });

        this.control.statusChanges
            .pipe(
                map((status: FormControlStatus) => status === "DISABLED"),
                startWith(this.control.disabled),
                untilDestroyed(this)
            )
            .subscribe((isDisabled: boolean) => {
                this.localStore.patchState({ isDisabled });
            });
    }

    protected clearValue(): void {
        this.control?.setValue("");
    }

    private isEmptyValue(value: unknown): boolean {
        return !value;
    }

    private createAriaLabelForInput() {
        const inputElement = this.formField?.nativeElement?.querySelector(".input-field > input");
        const label = this.formField?.nativeElement?.querySelector("label");
        const placeholder = inputElement?.getAttribute("placeholder");

        if (inputElement && label) {
            inputElement.setAttribute("aria-label", label.textContent ?? "");
        } else if (inputElement && placeholder) {
            inputElement.setAttribute("aria-label", placeholder);
        }
    }
}
