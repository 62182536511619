import { Injectable } from "@angular/core";
import { GeoZonesActions } from "@dtm-frontend/shared/map/geo-zones";
import { MAX_PAGE_SIZE_VALUE, MissionPlanRoute, Page, RouteData } from "@dtm-frontend/shared/ui";
import { FunctionUtils, RxjsUtils } from "@dtm-frontend/shared/utils";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { EMPTY, forkJoin } from "rxjs";
import { catchError, finalize, map, tap } from "rxjs/operators";
import { CaaPermitData, MissionPlanAnalysisStatus, MissionPreviewData, SoraSettings, TrafficMissionData } from "../../shared";
import {
    DEFAULT_SORTING,
    FilterConditions,
    Mission,
    MissionSearchError,
    MissionWithGeometry,
    MissionsListWithPages,
    Paging,
    Sorting,
} from "../models/mission-search.models";
import { MissionApiData } from "../services/mission-search-api.models";
import { MissionSearchApiService } from "../services/mission-search-api.service";
import { MissionSearchActions } from "./mission-search.actions";

export interface MissionSearchStateModel {
    filterConditions: FilterConditions | undefined;
    sorting: Sorting | undefined;
    missions: Mission[] | undefined;
    missionsPages: Page | undefined;
    mission: MissionPreviewData | undefined;
    flightPurposes: Record<string, string> | undefined;
    analysisStatus: MissionPlanAnalysisStatus | undefined;
    caaPermitData: CaaPermitData | undefined;
    nearbyMissionsRouteData: RouteData<TrafficMissionData>[] | undefined;
    isTrafficAnalysisProcessing: boolean;
    isFlightRequirementsProcessing: boolean;
    soraSettings: SoraSettings | undefined;
    route: MissionPlanRoute | undefined;
    isProcessing: boolean;
    isMapProcessing: boolean;
    missionsGeometries: MissionWithGeometry[] | undefined;
    areMissionsVisibleOnMap: boolean;
    getMissionsError: MissionSearchError | undefined;
    getMissionError: MissionSearchError | undefined;
    getMissionRouteError: MissionSearchError | undefined;
    getNearbyMissionsError: MissionSearchError | undefined;
    searchAirspaceElementsError: MissionSearchError | undefined;
    getMissionsRoutesError: MissionSearchError | undefined;
}

const defaultState: MissionSearchStateModel = {
    filterConditions: undefined,
    sorting: undefined,
    missions: undefined,
    missionsPages: undefined,
    mission: undefined,
    flightPurposes: undefined,
    analysisStatus: undefined,
    caaPermitData: undefined,
    nearbyMissionsRouteData: undefined,
    isTrafficAnalysisProcessing: false,
    isFlightRequirementsProcessing: false,
    soraSettings: undefined,
    route: undefined,
    isProcessing: false,
    isMapProcessing: false,
    missionsGeometries: undefined,
    areMissionsVisibleOnMap: false,
    getMissionsError: undefined,
    getMissionError: undefined,
    getMissionRouteError: undefined,
    getNearbyMissionsError: undefined,
    searchAirspaceElementsError: undefined,
    getMissionsRoutesError: undefined,
};

@State<MissionSearchStateModel>({
    name: "missionSearch",
    defaults: defaultState,
})
@Injectable()
export class MissionSearchState {
    @Selector()
    public static getMissionsError(state: MissionSearchStateModel): MissionSearchError | undefined {
        return state.getMissionsError;
    }

    @Selector()
    public static filterConditions(state: MissionSearchStateModel): FilterConditions | undefined {
        return state.filterConditions;
    }

    @Selector()
    public static sorting(state: MissionSearchStateModel): Sorting | undefined {
        return state.sorting;
    }

    @Selector()
    public static missions(state: MissionSearchStateModel): Mission[] {
        return state.missions ?? [];
    }

    @Selector()
    public static missionsPages(state: MissionSearchStateModel): Page | undefined {
        return state.missionsPages;
    }

    @Selector()
    public static isProcessing(state: MissionSearchStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static getMissionError(state: MissionSearchStateModel): MissionSearchError | undefined {
        return state.getMissionError;
    }

    @Selector()
    public static mission(state: MissionSearchStateModel): MissionPreviewData | undefined {
        return state.mission;
    }

    @Selector()
    public static route(state: MissionSearchStateModel): MissionPlanRoute | undefined {
        return state.route;
    }

    @Selector()
    public static getMissionRouteError(state: MissionSearchStateModel): MissionSearchError | undefined {
        return state.getMissionRouteError;
    }

    @Selector()
    public static getNearbyMissionsError(state: MissionSearchStateModel): MissionSearchError | undefined {
        return state.getNearbyMissionsError;
    }

    @Selector()
    public static searchAirspaceElementsError(state: MissionSearchStateModel): MissionSearchError | undefined {
        return state.searchAirspaceElementsError;
    }

    @Selector()
    public static flightPurposes(state: MissionSearchStateModel): Record<string, string> | undefined {
        return state.flightPurposes;
    }

    @Selector()
    public static analysisStatus(state: MissionSearchStateModel): MissionPlanAnalysisStatus | undefined {
        return state.analysisStatus;
    }

    @Selector()
    public static caaPermitData(state: MissionSearchStateModel): CaaPermitData | undefined {
        return state.caaPermitData;
    }

    @Selector()
    public static nearbyMissionsRouteData(state: MissionSearchStateModel): RouteData<TrafficMissionData>[] | undefined {
        return state.nearbyMissionsRouteData;
    }

    @Selector()
    public static isTrafficAnalysisProcessing(state: MissionSearchStateModel): boolean {
        return state.isTrafficAnalysisProcessing;
    }

    @Selector()
    public static isFlightRequirementsProcessing(state: MissionSearchStateModel): boolean {
        return state.isFlightRequirementsProcessing;
    }

    @Selector()
    public static soraSettings(state: MissionSearchStateModel): SoraSettings | undefined {
        return state.soraSettings;
    }

    @Selector()
    public static isMapProcessing(state: MissionSearchStateModel): boolean {
        return state.isMapProcessing;
    }

    @Selector()
    public static missionsGeometries(state: MissionSearchStateModel): MissionWithGeometry[] | undefined {
        return state.missionsGeometries;
    }

    @Selector()
    public static areMissionsVisibleOnMap(state: MissionSearchStateModel): boolean {
        return state.areMissionsVisibleOnMap;
    }

    @Selector()
    public static getMissionsRoutesError(state: MissionSearchStateModel): MissionSearchError | undefined {
        return state.getMissionsRoutesError;
    }

    constructor(private readonly missionSearchApi: MissionSearchApiService, private readonly store: Store) {
        if (missionSearchApi === undefined) {
            throw new Error("Initialize MissionSearchModule with .forRoot()");
        }
    }

    @Action(MissionSearchActions.SetFilterConditions)
    public setFilterConditions(context: StateContext<MissionSearchStateModel>, action: MissionSearchActions.SetFilterConditions) {
        context.patchState({
            filterConditions: action.filterConditions,
        });
    }

    @Action(MissionSearchActions.SetMissionsOrder)
    public setMissionsOrder(context: StateContext<MissionSearchStateModel>, action: MissionSearchActions.SetMissionsOrder) {
        context.patchState({
            sorting: action.sorting,
        });
    }

    @Action(MissionSearchActions.FetchMissions)
    public fetchMissions(context: StateContext<MissionSearchStateModel>, action: MissionSearchActions.FetchMissions) {
        context.patchState({
            isProcessing: true,
        });

        const { filterConditions, sorting, missionsPages } = context.getState();
        const paging: Paging = {
            size: missionsPages?.pageSize ?? action.maxResultsSize ?? MAX_PAGE_SIZE_VALUE,
            page: missionsPages?.pageNumber ?? 0,
        };

        if (!filterConditions) {
            context.patchState({
                missions: [],
                missionsPages: {
                    pageNumber: 0,
                    pageSize: paging.size,
                    totalElements: 0,
                },
                getMissionsError: undefined,
                isProcessing: false,
            });

            return;
        }

        return this.missionSearchApi.fetchMissions(filterConditions, sorting ?? DEFAULT_SORTING, paging).pipe(
            tap((result: MissionsListWithPages) =>
                context.patchState({
                    missions: result.content,
                    missionsPages: result.pages,
                    getMissionsError: undefined,
                })
            ),
            catchError((error) => {
                context.patchState({ missions: undefined, getMissionsError: error });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isProcessing: false,
                });
            })
        );
    }

    @Action(MissionSearchActions.GetMission)
    public getMission(context: StateContext<MissionSearchStateModel>, action: MissionSearchActions.GetMission) {
        context.patchState({
            mission: undefined,
            isProcessing: true,
        });

        return this.missionSearchApi.getMission(action.missionId).pipe(
            tap((result: MissionApiData) => {
                context.patchState({
                    mission: result.missionDetails,
                    flightPurposes: result.flightPurposes,
                    caaPermitData: result.caaPermitData,
                    analysisStatus: result.analysisStatus,
                    soraSettings: result.soraSettings,
                    getMissionError: undefined,
                });

                context.dispatch([
                    new MissionSearchActions.GetNearbyMissions(
                        (result.analysisStatus?.traffic?.nearbyMissionsPlanIds ?? []).filter(FunctionUtils.isTruthy),
                        (result.analysisStatus?.traffic?.collisionMissionsPlanIds ?? []).filter(FunctionUtils.isTruthy)
                    ),
                    new MissionSearchActions.ClearAirspaceElements(),
                ]);
            }),
            catchError((error) => {
                context.patchState({ getMissionError: error });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isProcessing: false,
                });
            })
        );
    }

    @Action(MissionSearchActions.GetMissionRoute)
    public getMissionRoute(context: StateContext<MissionSearchStateModel>, action: MissionSearchActions.GetMissionRoute) {
        context.patchState({
            route: undefined,
            isProcessing: true,
        });

        return this.missionSearchApi.getMissionRoute(action.routeId).pipe(
            tap((result: MissionPlanRoute) => {
                const { mission } = context.getState();

                return context.patchState({
                    route: result,
                    mission,
                    getMissionRouteError: undefined,
                });
            }),
            catchError((error) => {
                context.patchState({ getMissionRouteError: error });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isProcessing: false,
                });
            })
        );
    }

    @Action(MissionSearchActions.GetNearbyMissions)
    public getNearbyMissions(
        context: StateContext<MissionSearchStateModel>,
        { nearbyMissionsIds, collisionMissionsIds }: MissionSearchActions.GetNearbyMissions
    ) {
        context.patchState({
            nearbyMissionsRouteData: undefined,
            isTrafficAnalysisProcessing: true,
        });

        const allMissionsIds = [...nearbyMissionsIds, ...collisionMissionsIds];
        if (!allMissionsIds.length) {
            context.patchState({
                nearbyMissionsRouteData: [],
                isTrafficAnalysisProcessing: false,
            });

            return;
        }

        const nearbyMissions$ = allMissionsIds.map((nearbyMissionId) =>
            this.missionSearchApi.getPublicMissionData(nearbyMissionId).pipe(
                map((plan) => ({
                    route: plan.route,
                    data: plan,
                    isCollision: collisionMissionsIds.includes(plan.id),
                })),
                RxjsUtils.filterFalsy()
            )
        );

        return forkJoin(nearbyMissions$).pipe(
            tap((nearbyMissions) => {
                context.patchState({
                    nearbyMissionsRouteData: nearbyMissions.map(({ route, isCollision, data }) => ({
                        data: {
                            id: data.id,
                            flightStartAtMin: data.flightStartAtMin,
                            flightFinishAtMax: data.flightFinishAtMax,
                            flightType: data.capabilities?.flightType,
                            uavName: data.capabilities?.uavName,
                            routeId: route.routeId,
                        },
                        route: route,
                        isCollision: isCollision,
                    })),
                });
            }),
            catchError((error) => {
                context.patchState({ getNearbyMissionsError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isTrafficAnalysisProcessing: false }))
        );
    }

    @Action(MissionSearchActions.ClearMissionData)
    public clearMissionData(context: StateContext<MissionSearchStateModel>) {
        context.patchState({
            route: undefined,
            mission: undefined,
        });
    }

    @Action(MissionSearchActions.ClearAirspaceElements)
    public clearAirspaceElements() {
        this.store.dispatch(new GeoZonesActions.SetCustomElements());
    }

    @Action(MissionSearchActions.SearchAirspaceElements)
    public searchAirspaceElements(
        context: StateContext<MissionSearchStateModel>,
        { options }: MissionSearchActions.SearchAirspaceElements
    ) {
        context.patchState({ isFlightRequirementsProcessing: true });

        return this.missionSearchApi.searchAirspaceElements(options).pipe(
            tap((elements) => {
                this.store.dispatch(new GeoZonesActions.SetCustomElements(elements));
            }),
            catchError((error) => {
                context.patchState({
                    searchAirspaceElementsError: error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isFlightRequirementsProcessing: false }))
        );
    }

    @Action(MissionSearchActions.FetchMissionsRoutes)
    public fetchMissionsRoutes(context: StateContext<MissionSearchStateModel>, action: MissionSearchActions.FetchMissionsRoutes) {
        context.patchState({
            isMapProcessing: true,
        });

        const { missionsPages } = context.getState();
        const paging: Paging = {
            size: missionsPages?.pageSize ?? action.maxResultsSize ?? MAX_PAGE_SIZE_VALUE,
            page: missionsPages?.pageNumber ?? 0,
        };

        return this.missionSearchApi.fetchMissionsGeometries(action.missionsIds, paging).pipe(
            tap((result) =>
                context.patchState({
                    missionsGeometries: result,
                    getMissionsRoutesError: undefined,
                })
            ),
            catchError((error) => {
                context.patchState({ missionsGeometries: undefined, getMissionsRoutesError: error });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isMapProcessing: false,
                });
            })
        );
    }

    @Action(MissionSearchActions.SaveMissionsVisibilityOnMap)
    public saveMissionsVisibilityOnMap(
        context: StateContext<MissionSearchStateModel>,
        action: MissionSearchActions.SaveMissionsVisibilityOnMap
    ) {
        context.patchState({
            areMissionsVisibleOnMap: action.areMissionsVisibleOnMap,
        });
    }
}
