import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Uav } from "@dtm-frontend/shared/uav";
import { PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM } from "@dtm-frontend/shared/ui";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { UAV_ENDPOINTS, UavEndpoints } from "../uav.tokens";
import {
    CapabilitiesResponseBody,
    UavDetailsResponseBody,
    UavListResponseBody,
    convertCapabilitiesResponseBodyToCapabilities,
    convertUavDetailsResponseBodyToUav,
    convertUavListResponseBodyToUavsWithPages,
} from "./uav-api.converters";
import { UavCapabilities, UavErrorType, UavListFilterParams, UavsWithPages } from "./uav.models";

@Injectable({
    providedIn: "root",
})
export class UavApiService {
    constructor(private readonly httpClient: HttpClient, @Inject(UAV_ENDPOINTS) private readonly endpoints: UavEndpoints) {}

    public getUavs(params: UavListFilterParams): Observable<UavsWithPages> {
        let httpParams = new HttpParams()
            .set("page", `${params[PAGE_NUMBER_QUERY_PARAM] ?? 0}`)
            .set("size", `${params[PAGE_SIZE_QUERY_PARAM]}`);

        if (params.serialNumber) {
            httpParams = httpParams.append("serialNumbers", params.serialNumber);
        }

        if (params.operatorNumber) {
            httpParams = httpParams.append("operatorNumber", params.operatorNumber);
        }

        return this.httpClient.get<UavListResponseBody>(this.endpoints.getUavList, { params: httpParams }).pipe(
            map((response: UavListResponseBody) => convertUavListResponseBodyToUavsWithPages(response)),
            catchError(() => throwError(() => ({ type: UavErrorType.CannotGetUavs })))
        );
    }

    public getCapabilities(): Observable<UavCapabilities> {
        return this.httpClient.get<CapabilitiesResponseBody>(this.endpoints.getCapabilities).pipe(
            map((response: CapabilitiesResponseBody) => convertCapabilitiesResponseBodyToCapabilities(response)),
            catchError(() => throwError(() => ({ type: UavErrorType.Unknown })))
        );
    }

    public getUav(uavId: string): Observable<Uav> {
        return this.httpClient.get<UavDetailsResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getUavDetails, { uavId })).pipe(
            map((response: UavDetailsResponseBody) => convertUavDetailsResponseBodyToUav(this.endpoints.getUavModelPhoto, response)),
            catchError(() => throwError(() => ({ type: UavErrorType.NotFound })))
        );
    }
}
