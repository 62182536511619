<div class="uav-nav">
    <button type="button" class="button-secondary" routerLink="../list">
        <dtm-ui-icon name="arrow-go-back" />
        {{ "dtmAdminLibUav.uavCard.goBackToListButtonLabel" | transloco }}
    </button>
</div>
<ng-container *ngIf="(capabilitiesError$ | ngrxPush) || (uavError$ | ngrxPush) as error; else uavCardTemplate">
    <dtm-ui-error
        *ngIf="error.type === UavErrorType.NotFound"
        class="uav-nav"
        [errorMessage]="'dtmAdminLibUav.uavCard.uavNotFoundErrorMessage' | transloco"
    />
    <dtm-ui-error
        *ngIf="error.type !== UavErrorType.NotFound"
        class="uav-nav"
        [errorMessage]="'dtmAdminLibUav.uavCard.uavUnknownErrorMessage' | transloco"
    />
</ng-container>

<ng-template #uavCardTemplate>
    <dtm-ui-loader-container *ngrxLet="uav$ as uav" [isShown]="isProcessing$ | ngrxPush">
        <div class="grid">
            <dtm-uav-lib-uav-header dtmAdminLibUavDocumentsApiProvider [uav]="uav" class="uav-header" />
            <dtm-uav-lib-uav-setups
                dtmAdminLibSetupDocumentsApiProvider
                [setups]="uav.setups"
                [trackers]="trackers$ | ngrxPush"
                [navigationAccuracyItems]="navigationAccuracyItems$ | ngrxPush"
                [selectedSetupId]="selectedSetupId$ | ngrxPush"
                class="uav-setups"
            >
                <ng-container emptyEquipmentMessageSlot>
                    {{ "dtmAdminLibUav.uavCard.emptyEquipmentMessageLabel" | transloco }}
                </ng-container>
            </dtm-uav-lib-uav-setups>
        </div>
    </dtm-ui-loader-container>
</ng-template>
