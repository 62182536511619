import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, Input, Output } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { MissionProcessingPhase, MissionType } from "../../../../shared/models/mission.models";
import { Mission } from "../../../models/mission-search.models";

interface MissionItemComponentState {
    mission: Mission | undefined;
    isSelected: boolean;
}

@Component({
    selector: "dtm-mission-mission-search-mission-item[mission]",
    templateUrl: "./mission-item.component.html",
    styleUrls: ["./mission-item.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionItemComponent {
    @Input() public set mission(value: Mission | undefined) {
        this.localStore.patchState({ mission: value });
    }

    @HostBinding("class.mission-tile-selected")
    @Input()
    public set isSelected(value: BooleanInput) {
        this.localStore.patchState({ isSelected: coerceBooleanProperty(value) });
    }
    public get isSelected() {
        return this.localStore.selectSnapshotByKey("isSelected");
    }

    @Output() public readonly detailsShow = new EventEmitter<void>();

    public get missionId() {
        return this.localStore.selectSnapshotByKey("mission")?.id;
    }

    protected readonly MissionType = MissionType;
    protected readonly MissionProcessingPhase = MissionProcessingPhase;
    protected readonly mission$ = this.localStore.selectByKey("mission").pipe(RxjsUtils.filterFalsy());

    constructor(private readonly localStore: LocalComponentStore<MissionItemComponentState>, private readonly element: ElementRef) {
        this.localStore.setState({
            mission: undefined,
            isSelected: false,
        });
    }

    public scrollIntoMissionItem(parentElement?: HTMLElement): void {
        const nativeElement = this.element.nativeElement as HTMLElement;

        if (!parentElement) {
            nativeElement.scrollIntoView({ behavior: "smooth", block: "center" });

            return;
        }

        const parentBounding = parentElement.getBoundingClientRect();
        const elementBounding = nativeElement.getBoundingClientRect();

        if (elementBounding.top < parentBounding.top) {
            nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
        } else if (elementBounding.bottom > parentBounding.bottom) {
            nativeElement.scrollIntoView({ behavior: "smooth", block: "end" });
        }
    }
}
