import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";

@Component({
    templateUrl: "./operator-removal-info-dialog.component.html",
    styleUrls: ["./operator-removal-info-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperatorRemovalInfoDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: { operatorName: string } | undefined
    ) {}
}
