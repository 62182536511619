import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";
import { DialogService } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { UavInfo } from "../../models/uav.models";
import { SerialNumbersDialogComponent } from "../serial-numbers-dialog/serial-numbers-dialog.component";

interface UavInfoComponentState {
    uav: UavInfo | undefined;
    isShortMode: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-uav-lib-uav-info",
    templateUrl: "./uav-info.component.html",
    styleUrls: ["./uav-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UavInfoComponent {
    @Input({ required: true }) public set uav(value: UavInfo | undefined) {
        this.localStore.patchState({ uav: value });
    }

    @Input() public set isShortMode(value: BooleanInput) {
        this.localStore.patchState({ isShortMode: coerceBooleanProperty(value) });
    }

    @HostBinding("class.uav-info-short") protected get isShortModeEnabled() {
        return this.localStore.selectSnapshotByKey("isShortMode");
    }

    protected readonly uav$ = this.localStore.selectByKey("uav").pipe(RxjsUtils.filterFalsy());
    protected readonly isShortMode$ = this.localStore.selectByKey("isShortMode");

    constructor(private readonly localStore: LocalComponentStore<UavInfoComponentState>, private readonly dialogService: DialogService) {
        this.localStore.setState({
            uav: undefined,
            isShortMode: false,
        });
    }

    protected showSerialNumbers(uav: UavInfo) {
        return this.dialogService.open(SerialNumbersDialogComponent, {
            data: {
                serialNumbers: uav.serialNumbers,
            },
        });
    }
}
