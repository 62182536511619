import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { AircraftEntity } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface FlightTrackerDetailsComponentState {
    aircraftEntity: AircraftEntity | undefined;
}

@Component({
    selector: "dtm-map-flight-tracker-aircraft-details",
    templateUrl: "./flight-tracker-aircraft-details.component.html",
    styleUrls: ["./flight-tracker-aircraft-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class FlightTrackerAircraftDetailsComponent {
    @Input() public set aircraftEntity(value: AircraftEntity | undefined) {
        this.localStore.setState({ aircraftEntity: value });
    }
    @Output() public readonly detailsClose = new EventEmitter<void>();

    protected readonly aircraftEntity$ = this.localStore.selectByKey("aircraftEntity");

    constructor(private localStore: LocalComponentStore<FlightTrackerDetailsComponentState>) {
        this.localStore.setState({ aircraftEntity: undefined });
    }

    protected getAccuracy(accuracy: AircraftEntity["position"]["accuracy"]): string | undefined {
        if (!accuracy.latitude || !accuracy.longitude) {
            return;
        }

        return Math.max(accuracy.latitude, accuracy.longitude).toFixed(0);
    }
}
