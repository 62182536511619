<dtm-ui-loader-container
    *ngrxLet="{ isProcessing: isProcessing$, uavs: uavs$, expandedElement: expandedElement$ } as vm"
    [isShown]="vm.isProcessing"
>
    <table mat-table [dataSource]="vm.uavs" multiTemplateDataRows class="dtm-table">
        <ng-container matColumnDef="expandHandle">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button type="button" class="button-icon" (click)="$event.stopPropagation(); toggleExpandableRow(element)">
                    <dtm-ui-icon name="arrow-right" class="expand-handle" [class.expanded]="element === vm.expandedElement"></dtm-ui-icon>
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="serialNumber">
            <th mat-header-cell *matHeaderCellDef>
                {{ "dtmAdminLibUav.uavList.serialNumberColumnLabel" | transloco }}
            </th>
            <td mat-cell *matCellDef="let uav">
                <span
                    *ngIf="!uav.isSwarm"
                    [matTooltip]="uav.serialNumbers[0]"
                    [matTooltipDisabled]="uav.serialNumbers[0].length < MAX_SERIAL_NUMBER_CHARACTERS_DISPLAYED"
                >
                    {{ uav.serialNumbers[0] ?? "-" | ellipsifyText : MAX_SERIAL_NUMBER_CHARACTERS_DISPLAYED }}
                </span>
                <div class="cell-with-chips" *ngIf="uav.isSwarm">
                    <span
                        class="chip light"
                        [matTooltip]="'dtmAdminLibUav.uavList.swarmUavTooltipLabel' | transloco : { amount: uav.serialNumbers.length }"
                    >
                        <dtm-ui-icon name="drone-swarm"></dtm-ui-icon>
                    </span>
                    <a class="button-link-small" (click)="showSerialNumbers(uav); $event.stopImmediatePropagation()">
                        {{ "dtmAdminLibUav.uavList.showSerialNumbersButtonLabel" | transloco }}
                    </a>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="manufacturerAndModel">
            <th mat-header-cell *matHeaderCellDef>
                {{ "dtmAdminLibUav.uavList.manufacturerAndModelColumnLabel" | transloco }}
            </th>
            <td mat-cell *matCellDef="let uav">
                <div class="cell-with-chips">
                    <span class="chip light" *ngIf="uav.isCustom" [matTooltip]="'dtmAdminLibUav.uavList.customUavTooltipLabel' | transloco">
                        <dtm-ui-icon name="pencil-ruler"></dtm-ui-icon>
                    </span>
                    <span
                        [matTooltip]="uav.manufacturerAndModel"
                        [matTooltipDisabled]="uav.manufacturerAndModel.length < MAX_OPERATOR_NUMBER_CHARACTERS_DISPLAYED"
                    >
                        {{ uav.manufacturerAndModel | ellipsifyText : MAX_OPERATOR_NUMBER_CHARACTERS_DISPLAYED }}
                    </span>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="uavClass">
            <th mat-header-cell *matHeaderCellDef>
                {{ "dtmAdminLibUav.uavList.uavClassColumnLabel" | transloco }}
            </th>
            <td mat-cell *matCellDef="let uav">
                <span class="uav-class" *ngFor="let uavClass of uav.uavClasses">{{
                    "dtmAdminLibUav.uavList.uavClassValue" | transloco : { value: uavClass }
                }}</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="operatorNumber">
            <th mat-header-cell *matHeaderCellDef>
                {{ "dtmAdminLibUav.uavList.operatorNumberColumnLabel" | transloco }}
            </th>
            <td mat-cell *matCellDef="let uav">{{ uav.operatorNumber }}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibUav.uavList.actionColumnLabel" | transloco }}</th>
            <td mat-cell *matCellDef="let uav">
                <button [matMenuTriggerFor]="actions" class="button-icon" type="button" (click)="$event.stopPropagation()">
                    <dtm-ui-icon name="more" />
                </button>
                <mat-menu #actions="matMenu">
                    <button [routerLink]="['../', uav.id]" type="button" mat-menu-item>
                        <dtm-ui-icon name="information" />
                        {{ "dtmAdminLibUav.uavList.uavDetailsLabel" | transloco }}
                    </button>
                </mat-menu>
            </td>
        </ng-container>

        <ng-container matColumnDef="noResults">
            <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                <dtm-ui-no-results></dtm-ui-no-results>
            </td>
        </ng-container>
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let uav" [attr.colspan]="displayedColumns.length">
                <div class="expanded-row-content" *ngIf="uav === vm.expandedElement" @slideIn>
                    <dtm-ui-label-value
                        class="sub-cell"
                        [label]="'dtmAdminLibUav.uavList.uavTypeColumnLabel' | transloco"
                        [value]="'dtmAdminLibUav.uavList.uavTypeValue' | transloco : { value: uav.type }"
                    >
                    </dtm-ui-label-value>
                    <dtm-ui-label-value
                        class="sub-cell"
                        [label]="'dtmAdminLibUav.uavList.operatorNameColumnLabel' | transloco"
                        [value]="uav.ownerName"
                    >
                    </dtm-ui-label-value>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="pagination">
            <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                <dtm-ui-pagination *ngIf="pagination$ | ngrxPush as pagination" [pagination]="pagination" (page)="pageChange.emit($event)">
                </dtm-ui-pagination>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-footer-row *matFooterRowDef="['noResults']" [class.hide-footer]="vm.uavs.length || vm.isProcessing" class="no-results-row">
            <dtm-ui-no-results></dtm-ui-no-results>
        </tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            [class.expanded]="vm.expandedElement === row"
            (click)="toggleExpandableRow(row)"
        ></tr>
        <tr mat-row *matRowDef="let expandedRow; columns: ['expandedDetail']" class="expansion-row"></tr>
        <tr mat-footer-row *matFooterRowDef="['pagination']" [class.hide-footer]="!vm.uavs.length" class="pagination-row"></tr>
    </table>
</dtm-ui-loader-container>
