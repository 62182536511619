<dtm-mission-mission-search
    [filterConditions]="initialFilterConditions$ | ngrxPush"
    [maxResultsSize]="MAX_RESULTS_SIZE"
    [resultMissions]="missions$ | ngrxPush"
    [isProcessing]="isProcessing$ | ngrxPush"
    [resultMissionsGeometries]="missionsGeometries$ | ngrxPush"
    [isMapProcessing]="isMapProcessing$ | ngrxPush"
    [areMissionsInitiallyVisibleOnMap]="areMissionsInitiallyVisibleOnMap$ | ngrxPush"
    (orderChange)="changeOrder($event)"
    (filterChange)="changeFilter($event)"
    (detailsShow)="showDetails($event)"
    (routesFetch)="fetchRoutes($event)"
    (missionsVisibilityOnMapSave)="saveMissionsVisibilityOnMap($event)"
>
</dtm-mission-mission-search>
