import { HttpClient, HttpContext } from "@angular/common/http";
import { Directive, Inject, Injectable } from "@angular/core";
import { MODEL_DOCUMENT_API } from "@dtm-frontend/shared/uav";
import { FileUploadErrorType, FilesUploadApi } from "@dtm-frontend/shared/ui";
import { SKIP_NOT_FOUND_HTTP_INTERCEPTOR, StringUtils } from "@dtm-frontend/shared/utils";
import { EMPTY } from "rxjs";
import { UAV_ENDPOINTS, UavEndpoints } from "../uav.tokens";

@Injectable()
export class UavDocumentApiService implements FilesUploadApi {
    constructor(private readonly httpClient: HttpClient, @Inject(UAV_ENDPOINTS) private readonly endpoints: UavEndpoints) {}

    public uploadFile() {
        console.error("Not implemented");

        return EMPTY;
    }

    public getFile(fileId: string) {
        return this.httpClient.get(StringUtils.replaceInTemplate(this.endpoints.getUavModelDocument, { documentId: fileId }), {
            responseType: "blob",
            context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true),
        });
    }

    public manageUploadError(): { type: FileUploadErrorType } {
        return { type: FileUploadErrorType.Unknown };
    }
}

@Directive({
    selector: "[dtmAdminLibUavDocumentsApiProvider]",
    providers: [
        {
            provide: MODEL_DOCUMENT_API,
            useClass: UavDocumentApiService,
        },
    ],
})
export class UavDocumentsApiProviderDirective {}
