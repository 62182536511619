<ng-container
    *ngrxLet="{
        startDate: startDate$,
        finishDate: finishDate$,
        plannedStartTime: plannedStartTime$,
        plannedFinishTime: plannedFinishTime$,
        phase: phase$,
        missionType: missionType$,
        isAuthorityAcceptationConfirmed: isAuthorityAcceptationConfirmed$,
        isWholeDayMode: isWholeDayMode$,
    } as vm"
>
    <dtm-mission-badge-group
        class="badge-group"
        [missionType]="vm.missionType"
        [phase]="vm.phase"
        [isAuthorityAcceptationConfirmed]="vm.isAuthorityAcceptationConfirmed"
        (messageShow)="messageShow.emit()"
    ></dtm-mission-badge-group>

    <ng-content></ng-content>

    <div class="row">
        <dtm-mission-mission-dates
            [startDate]="vm.startDate"
            [finishDate]="vm.finishDate"
            [previousStartDate]="vm.plannedStartTime"
            [previousFinishDate]="vm.plannedFinishTime"
            [isWholeDayMode]="vm.isWholeDayMode"
        ></dtm-mission-mission-dates>
        <button class="button-icon" type="button" (click)="zoom.emit()">
            <dtm-ui-icon name="road-map"></dtm-ui-icon>
        </button>
    </div>
</ng-container>
