import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { EmptyStateMode } from "@dtm-frontend/shared/ui";
import { DateUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import {
    MissionCategory,
    MissionDataSimple,
    MissionPlanOperationCategoryOption,
    MissionPlanSpecificPermitType,
    MissionPlanStsOperationCategoryOption,
    MissionProcessingPhase,
} from "../../models/mission.models";

interface MissionDetailsComponentState {
    mission: MissionDataSimple | undefined;
    isExpanded: boolean;
}

@Component({
    selector: "dtm-mission-mission-details[mission]",
    templateUrl: "./mission-details.component.html",
    styleUrls: ["./mission-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionDetailsComponent {
    @Input() public set mission(value: MissionDataSimple | undefined) {
        this.localStore.patchState({ mission: value });
    }

    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState({ isExpanded: coerceBooleanProperty(value) });
    }

    protected readonly mission$ = this.localStore.selectByKey("mission").pipe(RxjsUtils.filterFalsy());
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");
    protected readonly MissionPlanSpecificPermitType = MissionPlanSpecificPermitType;
    protected readonly EmptyStateMode = EmptyStateMode;
    protected readonly MissionProcessingPhase = MissionProcessingPhase;

    constructor(private readonly localStore: LocalComponentStore<MissionDetailsComponentState>) {
        this.localStore.setState({
            mission: undefined,
            isExpanded: true,
        });
    }

    protected getOpenCategory(category?: MissionPlanOperationCategoryOption) {
        if (category?.type === MissionCategory.Open) {
            return category;
        }

        return;
    }

    protected getSpecificCategory(category?: MissionPlanOperationCategoryOption) {
        if (category?.type === MissionCategory.Specific) {
            return category;
        }

        return;
    }

    protected isSoraApplication(category: MissionPlanOperationCategoryOption | undefined): boolean {
        return (
            category?.type === MissionCategory.Specific &&
            category.specificPermitType === MissionPlanSpecificPermitType.Individual &&
            !category.specificCaaPermitId
        );
    }

    protected getAsStsCategoryType(category?: MissionPlanOperationCategoryOption) {
        return category as MissionPlanStsOperationCategoryOption;
    }

    protected getDaysBetween(mission: MissionDataSimple): number {
        const daysBetween = DateUtils.daysBetween(mission.flightStartAtMin, mission.flightFinishAtMax);

        return Math.max(daysBetween + 1, 1);
    }
}
