import { ClipboardModule } from "@angular/cdk/clipboard";
import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyPaginatorModule as MatPaginatorModule } from "@angular/material/legacy-paginator";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterLink } from "@angular/router";
import { SharedUavModule } from "@dtm-frontend/shared/uav";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { UavCardComponent } from "./components/uav-card/uav-card.component";
import { UavContainerComponent } from "./components/uav-container/uav-container.component";
import { UavFiltersComponent } from "./components/uav-filters/uav-filters.component";
import { UavListComponent } from "./components/uav-list/uav-list.component";
import { SetupDocumentsApiProviderDirective } from "./services/setup-document-api.service";
import { UavApiService } from "./services/uav-api.service";
import { UavDocumentsApiProviderDirective } from "./services/uav-document-api.service";
import { UavState } from "./state/uav.state";
import { UAV_ENDPOINTS } from "./uav.tokens";

@NgModule({
    declarations: [
        UavContainerComponent,
        UavListComponent,
        UavFiltersComponent,
        UavCardComponent,
        UavDocumentsApiProviderDirective,
        SetupDocumentsApiProviderDirective,
    ],
    imports: [
        NgxsModule.forFeature([UavState]),
        CommonModule,
        SharedUiModule,
        SharedI18nModule,
        PushModule,
        LetModule,
        MatCardModule,
        MatTableModule,
        MatPaginatorModule,
        MatDialogModule,
        ClipboardModule,
        MatTooltipModule,
        MatInputModule,
        ReactiveFormsModule,
        MatLegacyMenuModule,
        RouterLink,
        SharedUavModule.forRoot(),
    ],
    exports: [UavContainerComponent, UavListComponent, UavFiltersComponent],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmAdminLibUav",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: UavApiService,
            useValue: undefined,
        },
    ],
})
export class UavModule {
    public static forRoot(): ModuleWithProviders<UavModule> {
        return {
            ngModule: UavModule,
            providers: [
                {
                    provide: UavApiService,
                    useClass: UavApiService,
                },
            ],
        };
    }

    public static forTest(): ModuleWithProviders<UavModule> {
        return {
            ngModule: UavModule,
            providers: [
                {
                    provide: UavApiService,
                    useValue: null,
                },
                {
                    provide: UAV_ENDPOINTS,
                    useValue: {},
                },
            ],
        };
    }
}
