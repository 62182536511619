<ng-container *ngrxLet="{ missionsGeometries: missionsGeometries$, hasBackdrop: hasBackdrop$, isProcessing: isProcessing$ } as vm">
    <dtm-ui-loader-container [isShown]="vm.isProcessing">
        <ac-map [sceneMode]="SceneMode.SCENE3D" dtmMapPointerManager [class.backdrop]="vm.hasBackdrop">
            <dtm-map-azure-maps-layer [options]="AZURE_MAPS_LAYER_OPTIONS"></dtm-map-azure-maps-layer>
            <dtm-mission-mission-search-missions-layer
                [missions]="vm.missionsGeometries"
                [isShown]="!!vm.missionsGeometries.length && !vm.isProcessing"
                (missionSelect)="selectedMissionChange.emit($event)"
            ></dtm-mission-mission-search-missions-layer>
            <dtm-map-measures-cesium [isMeasureEnabled]="isMeasureToolActive$ | ngrxPush"></dtm-map-measures-cesium>
        </ac-map>
        <dtm-map-controls isMeasureToolEnabled (measureToolStatusChange)="updateMeasureToolStatus($event)"></dtm-map-controls>
    </dtm-ui-loader-container>
</ng-container>
