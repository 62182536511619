import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { ActivatedRoute, Router } from "@angular/router";
import { ErrorMode, MIDDLE_PAGE_SIZE_VALUE } from "@dtm-frontend/shared/ui";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import equal from "fast-deep-equal";
import { Observable, map, pairwise, startWith } from "rxjs";
import { first } from "rxjs/operators";
import { UavFilters, UavListFilterParams } from "../../services/uav.models";
import { UavActions } from "../../state/uav.actions";
import { UavState } from "../../state/uav.state";

@UntilDestroy()
@Component({
    templateUrl: "./uav-container.component.html",
    styleUrls: ["./uav-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UavContainerComponent implements OnInit {
    protected readonly isProcessing$ = this.store.select(UavState.isProcessing);
    protected readonly uavsError$ = this.store.select(UavState.uavsError);
    protected readonly uavs$ = this.store.select(UavState.uavs);
    protected readonly pagination$ = this.store.select(UavState.uavsPagination);
    protected readonly initialFilters$ = this.prepareInitialFilters();

    protected readonly ErrorMode = ErrorMode;
    @ViewChild("uavList", { read: ElementRef }) private uavList: ElementRef<HTMLHtmlElement> | undefined;

    constructor(private readonly route: ActivatedRoute, private readonly router: Router, private readonly store: Store) {}

    public ngOnInit() {
        this.watchUavListParamsChange();
        this.watchQueryParamsChange();
    }

    protected refreshUavList(): void {
        const persistedQueryParams = this.store.selectSnapshot(UavState.uavListParams);
        if (persistedQueryParams) {
            this.setUavListParams(persistedQueryParams);
        } else {
            this.setUavListParams({});
        }
    }

    protected pageChange(pageEvent: PageEvent): void {
        const uavListParams = this.store.selectSnapshot(UavState.uavListParams) ?? {};
        this.setUavListParams({
            ...uavListParams,
            page: `${pageEvent.pageIndex}`,
            size: `${pageEvent.pageSize}`,
        });
        this.scrollToTop();
    }

    protected updateFiltersState(filters: UavFilters): void {
        this.setUavListParams(this.prepareUavFiltersParams(filters));
    }

    private prepareInitialFilters(): Observable<UavFilters> {
        return this.store.select(UavState.uavListParams).pipe(
            first((params) => !!params),
            map((params) => ({
                serialNumber: params?.serialNumber ?? "",
                operatorNumber: params?.operatorNumber ?? "",
            }))
        );
    }

    private watchUavListParamsChange() {
        this.store
            .select(UavState.uavListParams)
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe((params: UavListFilterParams | undefined) => {
                this.router.navigate(["."], {
                    relativeTo: this.route,
                    queryParams: params,
                    replaceUrl: true,
                });
            });
    }

    private watchQueryParamsChange() {
        this.route.queryParams
            .pipe(startWith({}), pairwise(), untilDestroyed(this))
            .subscribe(([previousQueryParams, queryParams]: [UavListFilterParams, UavListFilterParams]) => {
                this.unifyUavListsParams(queryParams);
                const areQueryParamsEmpty = !Object.values(queryParams).length;

                // NOTE: if query params are empty, we pass the flow to router navigation to set basic query params
                if (areQueryParamsEmpty) {
                    return;
                }

                const hasFilterParamsBeenChanged = !equal({ ...previousQueryParams }, { ...queryParams });
                if (hasFilterParamsBeenChanged) {
                    this.store.dispatch(new UavActions.GetUavs(queryParams));
                }
            });
    }

    private unifyUavListsParams(queryParams: UavListFilterParams) {
        const persistedQueryParams = this.store.selectSnapshot(UavState.uavListParams);
        const areQueryParamsEmpty = !Object.values(queryParams).length;

        if (areQueryParamsEmpty) {
            if (persistedQueryParams) {
                this.setUavListParams(persistedQueryParams);
            } else {
                this.setUavListParams({});
            }
        } else if (!persistedQueryParams) {
            this.setUavListParams(queryParams);
        }
    }

    private setUavListParams(uavListParams: UavListFilterParams) {
        this.store.dispatch(
            new UavActions.SetUavListParams({
                page: "0",
                size: `${MIDDLE_PAGE_SIZE_VALUE}`,
                ...uavListParams,
            })
        );
    }

    private prepareUavFiltersParams(filters: UavFilters): UavListFilterParams {
        let params: UavListFilterParams = {
            page: "0",
        };

        if (filters.serialNumber) {
            params = { ...params, serialNumber: filters.serialNumber };
        }

        if (filters.operatorNumber) {
            params = { ...params, operatorNumber: filters.operatorNumber };
        }

        return params;
    }

    private scrollToTop() {
        this.uavList?.nativeElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }
}
