import { InjectionToken } from "@angular/core";

export interface MissionSearchEndpoints {
    getPlans: string;
    getPlanData: string;
    getPlanVerification: string;
    getPlanCaaPermitDetails: string;
    getPlanItinerary: string;
    getPublicDetails: string;
    getRoute: string;
    getPlansGeometries: string;
}

export const MISSION_SEARCH_ENDPOINTS = new InjectionToken<MissionSearchEndpoints>("Mission Search endpoints");
export const UAV_APP_URL = new InjectionToken<string>("Uav App URL");
