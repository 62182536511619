<section class="mission-tile" *ngrxLet="{ mission: mission$ } as vm">
    <div class="chip-list">
        <dtm-mission-mission-status-badge [status]="vm.mission.status"></dtm-mission-mission-status-badge>
        <span
            class="chip default"
            *ngIf="vm.mission.dtmNames.length"
            [matTooltip]="vm.mission.dtmNames | join"
            [matTooltipDisabled]="vm.mission.dtmNames.length < 2"
        >
            <ng-container *ngIf="vm.mission.dtmNames.length == 1">
                {{ vm.mission.dtmNames[0] }}
            </ng-container>
            <ng-container *ngIf="vm.mission.dtmNames.length > 1">
                {{ vm.mission.dtmNames[0] }},
                <span class="more-items">+{{ vm.mission.dtmNames.length - 1 }}</span>
            </ng-container>
        </span>
        <span class="chip disabled">
            <dtm-ui-icon [name]="vm.mission.flightType === MissionType.BVLOS ? 'eye-off' : 'eye'"></dtm-ui-icon>
            {{ "dtmSharedMissionSearch.missionTypeLabel" | transloco : { value: vm.mission.flightType } }}
        </span>
    </div>
    <div
        class="caa-permit-app-mission-dates"
        *ngIf="vm.mission.status === MissionProcessingPhase.CaaPermitApplication; else missionDatesTemplate"
    >
        <dtm-ui-icon name="calendar-event"></dtm-ui-icon>
        <span>
            {{ vm.mission.flightStartAt | localizeDate : { dateStyle: "short" } }} -
            {{ vm.mission.flightFinishAt | localizeDate : { dateStyle: "short" } }}
        </span>
        <span>
            (<dtm-ui-time-diff-display
                [firstDate]="vm.mission.flightStartAt"
                [secondDate]="vm.mission.flightFinishAt"
                shouldShowAsDaysBetween
            />)
        </span>
    </div>
    <ng-template #missionDatesTemplate>
        <dtm-mission-mission-time-range [missionTimeRange]="vm.mission" shouldDisplayDate></dtm-mission-mission-time-range>
    </ng-template>
    <ul class="mission-details">
        <li>
            <span class="label">{{ vm.mission.pilotName }}</span>
            <span class="value">{{ vm.mission.pilotNumber }}</span>
        </li>
        <li>
            <span class="label">{{ vm.mission.operatorName }}</span>
            <span class="value">{{ vm.mission.operatorNumber }}</span>
        </li>
        <li>
            <span class="label">{{ vm.mission.uavName }}</span>
            <span
                class="value"
                [matTooltip]="vm.mission.uavSerialNumbers | join"
                [matTooltipDisabled]="vm.mission.uavSerialNumbers.length < 2"
            >
                <ng-container *ngIf="vm.mission.uavSerialNumbers.length == 1">
                    {{ vm.mission.uavSerialNumbers[0] }}
                </ng-container>
                <ng-container *ngIf="vm.mission.uavSerialNumbers.length > 1">
                    {{ vm.mission.uavSerialNumbers[0] }},
                    <span class="more-items">+{{ vm.mission.uavSerialNumbers.length - 1 }}</span>
                </ng-container>
            </span>
        </li>
        <li class="mission-id">
            <span class="label">{{ "dtmSharedMissionSearch.listContainer.missionTile.missionIdLabel" | transloco }}</span>
            <span class="value">{{ vm.mission.id }}</span>
        </li>
    </ul>
    <section class="actions">
        <button type="button" class="button-icon" (click)="detailsShow.emit(); $event.stopPropagation()">
            <dtm-ui-icon name="arrow-go"></dtm-ui-icon>
        </button>
    </section>
</section>
