import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { InjectionToken, ModuleWithProviders, NgModule } from "@angular/core";
import { NgxsModule } from "@ngxs/store";
import { RxStomp, RxStompConfig } from "@stomp/rx-stomp";
import { RxStompService, WebsocketService } from "./services/websocket.service";

export const WEBSOCKET_CONFIGURATION = new InjectionToken<SharedWebsocketModuleConfig>("Websocket configuration");

export interface SharedWebsocketModuleConfig {
    endpoint: string;
    authorizationTokenProvider?: () => Promise<string>;
}

export function rxStompServiceFactory(config: RxStompConfig) {
    const rxStomp = new RxStompService();

    rxStomp.configure(config);
    rxStomp.activate();

    return rxStomp;
}

@NgModule({
    imports: [CommonModule, NgxsModule, HttpClientModule],
})
export class SharedWebsocketModule {
    private static rxStompConfigFactory(config: SharedWebsocketModuleConfig): RxStompConfig {
        return {
            brokerURL: config.endpoint,
            beforeConnect: async (client) => {
                if (!config.authorizationTokenProvider) {
                    return;
                }
                // TODO: Create true authorization DTM-954
                // client.stompClient.brokerURL += `?jwt=${await config.authorizationTokenProvider()}`;
            },
        };
    }

    public static forRoot(): ModuleWithProviders<SharedWebsocketModule> {
        return {
            ngModule: SharedWebsocketModule,
            providers: [
                {
                    provide: RxStompConfig,
                    useFactory: SharedWebsocketModule.rxStompConfigFactory,
                    deps: [WEBSOCKET_CONFIGURATION],
                },
                {
                    provide: RxStompService,
                    useFactory: rxStompServiceFactory,
                    deps: [RxStompConfig],
                },
                WebsocketService,
            ],
        };
    }

    public static forTest(): ModuleWithProviders<SharedWebsocketModule> {
        return {
            ngModule: SharedWebsocketModule,
            providers: [
                {
                    provide: RxStompConfig,
                    useValue: {
                        brokerURL: "ws://localhost:15674/ws",
                    },
                },
                {
                    provide: RxStompService,
                    useValue: new RxStomp(),
                },
                WebsocketService,
            ],
        };
    }
}
