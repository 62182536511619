<ng-container
    *ngrxLet="{
        aircraftEntity: aircraftEntity$,
        isMainAircraft: isMainAircraft$,
        isInVerificationMode: isInVerificationMode$,
        violationClass: violationClass$,
        name: name$,
        emergency: emergency$,
        isHeightVisible: isHeightVisible$,
        isInfoVisible: isInfoVisible$,
        phase: phase$,
        isSelected: isSelected$,
        isTrackerSelectionEnabled: isTrackerSelectionEnabled$,
    } as vm"
>
    <ng-container *ngIf="vm.aircraftEntity">
        <div
            class="aircraft-pin user-aircraft {{ vm.phase | invoke : getPhaseStyleClass }}"
            [class.verification]="vm.isInVerificationMode"
            [class.selected]="vm.isSelected"
            [ngClass]="vm.violationClass"
            [class.emergency]="vm.emergency"
            *ngIf="vm.isMainAircraft"
        >
            <div class="no-signal" *ngIf="vm.aircraftEntity.isConnectionLost">
                <dtm-ui-icon name="pin-off"></dtm-ui-icon>
            </div>
            <div class="aircraft-icon" [ngSwitch]="vm.emergency" (click)="toggleInfoVisibility()">
                <dtm-ui-icon name="drone" class="drone"></dtm-ui-icon>
                <dtm-ui-icon class="emergency-icon drone" name="tools" *ngSwitchCase="EmergencyType.TechnicalIssue"></dtm-ui-icon>
                <dtm-ui-icon class="emergency-icon drone" name="wifi-off" *ngSwitchCase="EmergencyType.LostControl"></dtm-ui-icon>
                <dtm-ui-icon class="emergency-icon drone" name="hems-emr" *ngSwitchCase="EmergencyType.EmergencyLanding"></dtm-ui-icon>
            </div>

            <ng-container
                [ngTemplateOutlet]="entityInfoTemplate"
                [ngTemplateOutletContext]="{ $implicit: vm.aircraftEntity, name: vm.name }"
            ></ng-container>
        </div>
        <div
            class="aircraft-pin"
            [class.farada]="vm.aircraftEntity.isFaradaSource"
            *ngIf="!vm.isMainAircraft"
            [class.active]="vm.isInfoVisible"
        >
            <div class="aircraft-icon" (click)="toggleInfoVisibility()">
                <div class="no-signal" *ngIf="vm.aircraftEntity?.isConnectionLost">
                    <dtm-ui-icon name="pin-off"></dtm-ui-icon>
                </div>
                <div
                    class="drone-icon-underlay"
                    [class.no-track]="!vm.aircraftEntity.position.track && vm.aircraftEntity.position.track !== 0"
                    [class.plane]="vm.aircraftEntity.aircraftType === AircraftType.Airplane"
                    [ngStyle]="vm.aircraftEntity.position.track | invoke : getAircraftRotationStyles"
                ></div>
                <dtm-ui-icon name="drone" class="aircraft" *ngIf="vm.aircraftEntity.aircraftType === AircraftType.Uav"></dtm-ui-icon>
                <dtm-ui-icon
                    name="plane"
                    class="aircraft plane"
                    [ngStyle]="vm.aircraftEntity.position.track | invoke : getAircraftRotationStyles"
                    *ngIf="vm.aircraftEntity.aircraftType === AircraftType.Airplane"
                ></dtm-ui-icon>
            </div>
            <ng-container
                [ngTemplateOutlet]="entityInfoTemplate"
                [ngTemplateOutletContext]="{ $implicit: vm.aircraftEntity }"
            ></ng-container>
        </div>
        <ng-template #entityInfoTemplate>
            <div class="drone-info" *ngIf="vm.isInfoVisible">
                <dtm-ui-triangle-pointer-svg class="pointer"></dtm-ui-triangle-pointer-svg>
                <div class="content">
                    <ng-container *ngIf="vm.name ?? vm.aircraftEntity.name as name">
                        <button
                            type="button"
                            class="button-secondary"
                            *ngIf="vm.isTrackerSelectionEnabled && vm.isMainAircraft"
                            (click)="select()"
                        >
                            {{ name }}
                        </button>
                        <span class="name" *ngIf="!vm.isTrackerSelectionEnabled || !vm.isMainAircraft" [title]="name">
                            {{ name }}
                        </span>
                    </ng-container>
                    <button type="button" class="button-tertiary" (click)="toggleHeightVisibility()">
                        {{ "dtmMapCesium.flightPin.toggleAglHeightVisibilityLabel" | transloco : { isVisible: vm.isHeightVisible } }}
                    </button>
                </div>
                <div class="flight-info">
                    <span class="label">{{ "dtmMapCesium.flightPin.heightLabel" | transloco }}:</span>
                    <div class="value">
                        <span>
                            <ng-container
                                *ngTemplateOutlet="valueTemplate; context: { $implicit: vm.aircraftEntity.position.altitude }"
                            ></ng-container>
                            {{ "dtmMapCesium.flightPin.amslLabel" | transloco }}
                        </span>
                        <span *ngIf="vm.isHeightVisible">
                            <br />
                            <ng-container *ngTemplateOutlet="valueTemplate; context: { $implicit: vm.aircraftEntity.position.height }">
                            </ng-container>
                            {{ "dtmMapCesium.flightPin.aglLabel" | transloco }}
                        </span>
                    </div>

                    <ng-container *ngIf="vm.aircraftEntity.position.accuracy | invoke : getAccuracy as accuracy">
                        <span class="label"><dtm-ui-icon name="shape-cylinder" />:</span>
                        <p class="value">
                            {{ "dtmMapCesium.flightPin.accuracyInMeters" | transloco : { value: accuracy } }}
                        </p>
                    </ng-container>
                    <div class="details-button">
                        <button type="button" class="button-icon" (click)="openDetails()">
                            <dtm-ui-icon name="arrow-go" />
                        </button>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-container>
</ng-container>
<ng-template #valueTemplate let-value>
    {{ value ? ("dtmMapCesium.flightPin.valueInMeters" | transloco : { value }) : ". . . . . . . . " }}
</ng-template>
