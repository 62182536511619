import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { ErrorResponseBody, FunctionUtils } from "@dtm-frontend/shared/utils";
import { CountryCode } from "libphonenumber-js";
import { PageResponseBody } from "../../components/pagination/pagination.models";
import { Operator } from "../../models/permits.models";
import { PhoneNumber } from "../../models/phone-number.models";
import {
    AircraftType,
    Elevation,
    FlightPositionUpdate,
    FlightPositionUpdateResponseBody,
    FlightPositionUpdateType,
    FlightSourceType,
    HemsData,
    HemsDataResponseBody,
    HemsEventData,
    MissionPlanRoute,
    MissionPlanRouteFlightZone,
    MissionPlanRouteSegment,
    SectionElementResponseBody,
    TimeRange,
    TrajectoryPositionBodyResponse,
    Waypoint,
} from "../../models/route-area.model";
import {
    Checkin,
    CheckinMessageEvent,
    CheckinStatus,
    EmergencyType,
    Mission,
    MissionData,
    MissionPlanOperationCategoryOption,
    MissionStatus,
    MissionType,
    TacticalError,
    TacticalErrorType,
    TrackerResponseBody,
    Uav,
    Violation,
} from "../../models/tactical.models";

const PHONE_NUMBER_CONVERSION_REGEXP = /^(\d+)\((\w+)\)$/;
const FARADA_SOURCE_NAME = "FaradaApi";

interface MissionPlanRouteResponseBody {
    routeId: string;
    planId: string;
    missionId: string;
    elevations: Elevation[];
    sections: SectionElementResponseBody[];
    estimatedDistance?: number;
    pathBased?: boolean;
}

export interface MissionListResponseBody {
    content: MissionResponseBodyContent[];
    number: number;
    size: number;
    totalElements: number;
    totalPages: number;
    numberOfElements: number;
    hasContent: boolean;
    first: boolean;
    last: boolean;
}

interface MissionResponseBodyContent {
    missionId: string;
    planId: string;
    name?: string;
    flightType: MissionType;
    status: MissionStatus;
    uav: Uav;
    operator: Operator;
    startTime: TimeRange;
    endTime: TimeRange;
    plannedStartTime: TimeRange;
    plannedEndTime: TimeRange;
    realizationTime: string | null;
    pathBased?: boolean;
    dtmNames: string[];
    operatorName: string;
    timeOverrideJustification?: string;
    pilotId: string;
}

export interface MissionDataResponseBody {
    mission: MissionWithRouteDataResponseBody;
    activation: {
        earlyActivationTime: string;
        realizationTime: string;
    };
    trajectories?: TrajectoryResponseBody[];
    trackers: TrackerResponseBody[];
    flightViolations: ViolationResponseBody[];
}

export interface ViolationResponseBody {
    flightId: string;
    trackerIdentifier: string;
    missionId: string;
    violationType: Violation;
    operatorId: string;
    uavName: string;
    startPosition: {
        track: number;
        latitude: number;
        longitude: number;
        altitude: number;
        height: number;
    };
    startedAt: string;
    finishedAt: string;
    reviewed: boolean;
}

export interface MissionResponseBody {
    activatedAt?: string;
    dtmNames: string[];
    earlyActivationTime: string;
    endTime: TimeRange;
    missionId: string;
    name: string;
    operationCategory: MissionPlanOperationCategoryOption;
    pathBased: boolean;
    pilotId: string;
    planId: string;
    pilot?: {
        phoneNumber?: string;
    };
    realizationTime: Date | null;
    flightType: MissionType;
    startTime: TimeRange;
    status: MissionStatus;
    routeId: string;
    uav: Uav;
    operatorName: string;
}

export interface MissionWithRouteDataResponseBody {
    activatedAt?: string;
    dtmNames: string[];
    endTime: TimeRange;
    flightType: MissionType;
    missionId: string;
    name: string;
    observers: string[];
    operationCategory: MissionPlanOperationCategoryOption;
    pathBased: boolean;
    pilotId: string;
    planId: string;
    realizationTime?: string;
    route: MissionRouteResponseBody;
    startTime: TimeRange;
    plannedStartTime: TimeRange;
    plannedEndTime: TimeRange;
    startedAt?: string;
    status: MissionStatus;
    uav: Uav;
    operatorName: string;
    timeOverrideJustification?: string;
}

export interface MissionMapItemListResponseBody {
    base: MissionResponseBody;
    route: MissionRouteResponseBody;
}

export type MissionRouteResponseBody = Omit<MissionPlanRouteResponseBody, "sections"> & { elements: SectionElementResponseBody[] };

export type MissionMapDataListResponseBody = Omit<MissionListResponseBody, "content"> & { content: MissionMapItemListResponseBody[] };

export interface DeactivationEventResponseBody {
    id: string;
    trackerIdentifier: string;
    sectionIndex: number;
    receivers: string[];
    createdAt: string;
}

export interface TrajectoryResponseBody {
    id?: {
        id: string;
    };
    flightId?: {
        id: string;
    };
    trackerIdentifier?: string;
    startTime?: string;
    endTime?: string;
    positions: TrajectoryPositionBodyResponse[];
    active?: boolean;
}

export interface EmergencyResponseBody {
    emergency?: {
        type?: EmergencyType;
    };
}

export type EmergencyRequestBody = EmergencyResponseBody;

export type DeviceHistoryResponseBody = PageResponseBody<CheckinMessageEvent>;

export interface CheckinsParams {
    listRequest: {
        dtmName?: string;
        authorityUnitId?: string;
        startFrom?: Date;
        endTo?: Date;
    };
    page: {
        size: number;
        page: number;
    };
}

export function transformMissionListResponse(response: MissionListResponseBody): Mission[] {
    return response.content
        .filter(({ dtmNames }) => dtmNames.length) // TODO: DTM-4104 this should be filtered by backend
        .map((mission) => ({
            missionId: mission.missionId,
            status: mission.status,
            uav: mission.uav,
            name: mission.name,
            operator: mission.operator,
            planId: mission.planId,
            flightType: mission.flightType,
            startTime: {
                min: new Date(mission.startTime.min),
                max: new Date(mission.startTime.max),
            },
            endTime: {
                min: new Date(mission.endTime.min),
                max: new Date(mission.endTime.max),
            },
            plannedStartTime: {
                min: new Date(mission.plannedStartTime.min),
                max: new Date(mission.plannedStartTime.max),
            },
            plannedEndTime: {
                min: new Date(mission.plannedEndTime.min),
                max: new Date(mission.plannedEndTime.max),
            },
            realizationTime: mission.realizationTime ? new Date(mission.realizationTime) : null,
            isPathBased: mission.pathBased,
            dtmNames: mission.dtmNames,
            operatorName: mission.operatorName,
            timeOverrideJustification: mission.timeOverrideJustification,
            pilotId: mission.pilotId,
        }))
        .map((mission) => {
            mission.startTime.min.setSeconds(0, 0);
            mission.startTime.max.setSeconds(0, 0);
            mission.endTime.min.setSeconds(0, 0);
            mission.endTime.max.setSeconds(0, 0);
            mission.plannedStartTime.min.setSeconds(0, 0);
            mission.plannedStartTime.max.setSeconds(0, 0);
            mission.plannedEndTime.min.setSeconds(0, 0);
            mission.plannedEndTime.max.setSeconds(0, 0);

            return mission;
        });
}

const CHECKIN_STATUSES = [CheckinStatus.InRealization, CheckinStatus.Submitted, CheckinStatus.Expired, CheckinStatus.Completed];

function parseWaypointResponseDates(waypoint: Waypoint, waypointIndex: number): Waypoint {
    const prefix = "A";

    return {
        ...waypoint,
        name: `${prefix}${waypointIndex}`,
        estimatedArriveAt: {
            min: new Date(waypoint.estimatedArriveAt.min),
            max: new Date(waypoint.estimatedArriveAt.max),
        },
    };
}

export function convertMissionPlanRouteResponseBodyToMissionPlanRoute(response: MissionRouteResponseBody): MissionPlanRoute {
    let sectionId = 0;

    return {
        routeId: response.routeId,
        planId: response.planId,
        missionId: response.missionId,
        elevations: response.elevations,
        isPathBased: !!response.pathBased,
        estimatedDistance: response.estimatedDistance,
        sections: response.elements.map((element, index) => {
            const flightZone: MissionPlanRouteFlightZone | undefined = !element.flightZone
                ? undefined
                : {
                      center: parseWaypointResponseDates(
                          element.flightZone.center,
                          response.elements[index - 1]?.segment ? (sectionId += 2) : ++sectionId
                      ),
                      flightArea: element.flightZone.flightArea,
                      safetyArea: element.flightZone.safetyArea,
                      groundArea: element.flightZone.ground?.area,
                      groundAdjacentArea: element.flightZone.groundAdjacent?.area,
                      stopover: element.flightZone.stopover,
                      isRunway: element.flightZone.runway,
                  };

            const segment: MissionPlanRouteSegment | undefined = !element.segment
                ? undefined
                : {
                      fromWaypoint: parseWaypointResponseDates(element.segment.from, ++sectionId),
                      toWaypoint: parseWaypointResponseDates(element.segment.to, sectionId + 1),
                      flightArea: element.segment.flightArea,
                      safetyArea: element.segment.safetyArea,
                      groundArea: element.segment.ground?.area,
                      groundAdjacentArea: element.segment.groundAdjacent?.area,
                      elevationProfile: element.segment.elevationProfile,
                  };

            return { flightZone, segment, isActive: element.active };
        }),
    };
}

export function transformMissionDataResponse({
    mission,
    trajectories,
    trackers,
    flightViolations,
    activation,
}: MissionDataResponseBody): MissionData {
    const missionData: MissionData = {
        activatedAt: mission.activatedAt ? new Date(mission.activatedAt) : null,
        isPathBased: mission.pathBased,
        realizationTime: mission.realizationTime ? new Date(mission.realizationTime) : null,
        route: convertMissionPlanRouteResponseBodyToMissionPlanRoute(mission.route),
        status: mission.status,
        uav: mission.uav,
        name: mission.name,
        missionId: mission.missionId,
        flightType: mission.flightType,
        planId: mission.planId,
        earlyActivationTime: new Date(activation.earlyActivationTime),
        endTime: {
            min: new Date(mission.endTime.min),
            max: new Date(mission.endTime.max),
        },
        startTime: {
            min: new Date(mission.startTime.min),
            max: new Date(mission.startTime.max),
        },
        plannedStartTime: {
            min: new Date(mission.plannedStartTime.min),
            max: new Date(mission.plannedStartTime.max),
        },
        plannedEndTime: {
            min: new Date(mission.plannedEndTime.min),
            max: new Date(mission.plannedEndTime.max),
        },
        trajectories: trajectories
            ?.map((trajectory) => ({
                id: trajectory.id?.id,
                flightId: trajectory.flightId?.id,
                trackerIdentifier: trajectory.trackerIdentifier,
                startTime: trajectory.startTime ? new Date(trajectory.startTime) : undefined,
                endTime: trajectory.endTime ? new Date(trajectory.endTime) : undefined,
                positions: trajectory.positions
                    .map((position) => ({ ...position, timestamp: position.timestamp ? new Date(position.timestamp) : undefined }))
                    .sort((left, right) => (left.timestamp?.getTime() ?? 0) - (right.timestamp?.getTime() ?? 0)),
                isActive: trajectory.active,
            }))
            .sort((left, right) => (left.startTime?.getTime() ?? 0) - (right.startTime?.getTime() ?? 0)),
        dtmNames: mission.dtmNames,
        trackers: trackers.map((tracker) => ({
            trackerIdentifier: tracker.trackerIdentifier,
            trackerType: tracker.trackerTypeId,
            isConfirmed: tracker.confirmed,
        })),
        operatorName: mission.operatorName,
        activeViolations: flightViolations.filter(({ finishedAt }) => !finishedAt).map((violation) => violation.violationType),
        timeOverrideJustification: mission.timeOverrideJustification,
        pilotId: mission.pilotId,
    };

    missionData.startTime.min.setSeconds(0, 0);
    missionData.startTime.max.setSeconds(0, 0);
    missionData.endTime.min.setSeconds(0, 0);
    missionData.endTime.max.setSeconds(0, 0);
    missionData.plannedStartTime?.min.setSeconds(0, 0);
    missionData.plannedStartTime?.max.setSeconds(0, 0);
    missionData.plannedEndTime?.min.setSeconds(0, 0);
    missionData.plannedEndTime?.max.setSeconds(0, 0);

    return missionData;
}

export function transformMissionMapItemListResponse({ base: mission, route }: MissionMapItemListResponseBody): MissionData {
    let pilotPhoneNumber: PhoneNumber | undefined;
    const phoneNumberMatch = mission.pilot?.phoneNumber ? PHONE_NUMBER_CONVERSION_REGEXP.exec(mission.pilot.phoneNumber) : undefined;

    if (phoneNumberMatch) {
        pilotPhoneNumber = {
            number: phoneNumberMatch[1],
            countryCode: phoneNumberMatch[2] as CountryCode,
        };
    }

    return {
        activatedAt: mission.activatedAt ? new Date(mission.activatedAt) : null,
        isPathBased: mission.pathBased,
        realizationTime: mission.realizationTime ? new Date(mission.realizationTime) : null,
        route: convertMissionPlanRouteResponseBodyToMissionPlanRoute(route),
        status: mission.status,
        uav: mission.uav,
        name: mission.name,
        missionId: mission.missionId,
        flightType: mission.flightType,
        planId: mission.planId,
        earlyActivationTime: new Date(mission.earlyActivationTime),
        endTime: {
            min: new Date(mission.endTime.min),
            max: new Date(mission.endTime.max),
        },
        startTime: {
            min: new Date(mission.startTime.min),
            max: new Date(mission.startTime.max),
        },
        pilotPhoneNumber,
        trajectories: [],
        dtmNames: mission.dtmNames,
        operatorName: mission.operatorName,
        pilotId: mission.pilotId,
    };
}

function getErrorType(errorResponse: HttpErrorResponse, defaultErrorType?: TacticalErrorType): TacticalErrorType {
    switch (errorResponse.status) {
        case HttpStatusCode.Forbidden:
            return TacticalErrorType.NotAuthorized;
        case HttpStatusCode.NotFound:
            return TacticalErrorType.NotFound;
        default:
            return defaultErrorType ?? TacticalErrorType.Unknown;
    }
}

export function transformTacticalErrorResponse(error: HttpErrorResponse, defaultErrorType?: TacticalErrorType): TacticalError {
    const errorBody: ErrorResponseBody = error.error;

    return {
        type: getErrorType(error, defaultErrorType),
        code: errorBody.generalMessage,
        fieldErrors: errorBody.fieldErrors?.map(({ args, code }) => ({
            args,
            code,
        })),
    };
}

// TODO: DTM-3454 - it is temporary solution to work with drone traffic
function isUav(response: FlightPositionUpdateResponseBody, callSign?: string) {
    if (response.sourceType === FlightSourceType.RemoteId || response.operator?.remoteIdOperatorId) {
        return true;
    }

    return callSign?.startsWith("23");
}

export function transformFlightPositionUpdateResponseBodyToFlightPositionUpdate(
    response: FlightPositionUpdateResponseBody,
    updateType: FlightPositionUpdateType
): FlightPositionUpdate {
    const callSign = response.uav?.name;
    let aircraftType: AircraftType = AircraftType.Other;

    if (isUav(response, callSign)) {
        aircraftType = AircraftType.Uav;
    } else if (callSign) {
        aircraftType = AircraftType.Airplane;
    }

    return {
        updateType,
        aircraftType,
        callSign,
        id: response.flightId,
        trackerIdentifier: response.trackerIdentifier,
        isFaradaSource: response.lastSource === FARADA_SOURCE_NAME,
        operatorId: response.operatorId,
        operator: response.operator,
        receivedSignalStrengthIndication: response.signal?.rssi,
        position: response.position,
        sourceType: response.sourceType,
    } satisfies FlightPositionUpdate;
}

export function covertHemsDataResponseBodyToHemsEventData(response: HemsDataResponseBody): HemsEventData[] {
    return response.content
        .map(({ body, name }) => {
            let content: HemsData;
            try {
                content = JSON.parse(body);
            } catch {
                return undefined;
            }

            content = {
                ...content,
                createdAt: new Date(content.createdAt),
                lastPositionChangeTime: content.lastPositionChangeTime ? new Date(content.lastPositionChangeTime) : null,
                landing: content.landing
                    ? {
                          ...content.landing,
                          activatedAt: content.landing.activatedAt ? new Date(content.landing.activatedAt) : null,
                          estimatedArrivalTime: content.landing.estimatedArrivalTime
                              ? new Date(content.landing.estimatedArrivalTime)
                              : null,
                      }
                    : undefined,
                departure: content.departure
                    ? {
                          ...content.departure,
                          activatedAt: content.departure.activatedAt ? new Date(content.departure.activatedAt) : null,
                          estimatedArrivalTime: content.departure.estimatedArrivalTime
                              ? new Date(content.departure.estimatedArrivalTime)
                              : null,
                      }
                    : undefined,
                predictedPosition: content.predictedPosition
                    ? {
                          ...content.predictedPosition,
                          arrivalTime: content.predictedPosition.arrivalTime ? new Date(content.predictedPosition.arrivalTime) : undefined,
                      }
                    : undefined,
            };

            return {
                name,
                content,
            };
        })
        .filter(FunctionUtils.isTruthy);
}

export function convertEmergencyResponseBodyToEmergencyType(response: EmergencyResponseBody): EmergencyType | undefined {
    return response.emergency?.type;
}

export const getCheckinListParams = (listRequest: CheckinsParams["listRequest"], maximumListSize: number): CheckinsParams => ({
    listRequest,
    page: {
        size: maximumListSize,
        page: 0,
    },
});

export const convertCheckinEventBodyToCheckin = (checkinMessage: CheckinMessageEvent): Checkin => ({
    id: checkinMessage.id,
    uavName: checkinMessage?.flightDetails?.uavName,
    flightStartAt: new Date(checkinMessage.startTime),
    flightFinishAt: new Date(checkinMessage.endTime),
    phoneNumber: checkinMessage?.pilot?.phoneNumber
        ? {
              countryCode: "PL",
              number: checkinMessage.pilot.phoneNumber,
          }
        : undefined,
    heightAgl: checkinMessage.zone ? checkinMessage.zone.ceiling - checkinMessage.zone.floor : undefined,
    isEmergency: false, // TODO: DTM-3736 - handle emergency case
    pilotNumber: checkinMessage?.pilot?.number,
    status: checkinMessage.status,
    amslCeil: checkinMessage.zone?.ceiling,
    checkinType: MissionType.VLOS,
    geometry: checkinMessage.aproxArea,
    missionId: checkinMessage.missionId,
});

export const convertCheckinResponseBodyToCheckinList = (response: CheckinMessageEvent[]): Checkin[] =>
    response
        .map((checkinResponse) => convertCheckinEventBodyToCheckin(checkinResponse))
        .filter((checkin) => !checkin.missionId && CHECKIN_STATUSES.some((status) => status === checkin.status));
