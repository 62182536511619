import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface HeaderUserButtonComponentState {
    isUserLoggedIn: boolean;
    isShortMode: boolean;
    userName: string | undefined;
    avatarUrl: string | undefined;
    isExpanded: boolean;
}

@Component({
    selector: "dtm-ui-header-user-button[isUserLoggedIn]",
    templateUrl: "./header-user-button.component.html",
    styleUrls: ["./header-user-button.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class HeaderUserButtonComponent {
    @Input() public set isUserLoggedIn(value: BooleanInput) {
        this.localStore.patchState({ isUserLoggedIn: coerceBooleanProperty(value) });
    }

    @Input() public set isShortMode(value: BooleanInput) {
        this.localStore.patchState({ isShortMode: coerceBooleanProperty(value) });
    }

    @Input() public set userName(value: string | undefined) {
        this.localStore.patchState({ userName: value });
    }

    @Input() public set avatarUrl(value: string | undefined) {
        this.localStore.patchState({ avatarUrl: value });
    }

    @Input() public set isExpanded(value: boolean) {
        this.localStore.patchState({ isExpanded: value });
    }

    @Output() public goToUserProfilePage = new EventEmitter();
    @Output() public login = new EventEmitter();
    @Output() public logout = new EventEmitter();

    protected readonly isUserLoggedIn$ = this.localStore.selectByKey("isUserLoggedIn");
    protected readonly isShortMode$ = this.localStore.selectByKey("isShortMode");
    protected readonly userName$ = this.localStore.selectByKey("userName");
    protected readonly avatarUrl$ = this.localStore.selectByKey("avatarUrl");
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");

    constructor(private readonly localStore: LocalComponentStore<HeaderUserButtonComponentState>) {
        localStore.setState({
            isUserLoggedIn: false,
            isShortMode: false,
            userName: undefined,
            avatarUrl: undefined,
            isExpanded: false,
        });
    }

    protected patchIsExpanded(value: boolean) {
        this.localStore.patchState({ isExpanded: value });
    }
}
