import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { DateUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { combineLatestWith, map } from "rxjs/operators";

interface MissionDatesComponentState {
    startDate: Date;
    finishDate: Date;
    previousStartDate: Date | undefined;
    previousFinishDate: Date | undefined;
    isWholeDayMode: boolean;
}

@Component({
    selector: "dtm-mission-mission-dates[startDate][finishDate]",
    templateUrl: "./mission-dates.component.html",
    styleUrls: ["./mission-dates.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionDatesComponent {
    @Input() public set startDate(value: Date) {
        this.localStore.patchState({ startDate: value });
    }
    @Input() public set finishDate(value: Date) {
        this.localStore.patchState({ finishDate: value });
    }
    @Input() public set previousStartDate(value: Date | undefined) {
        this.localStore.patchState({ previousStartDate: value });
    }
    @Input() public set previousFinishDate(value: Date | undefined) {
        this.localStore.patchState({ previousFinishDate: value });
    }
    @Input() public set isWholeDayMode(value: BooleanInput) {
        this.localStore.patchState({ isWholeDayMode: coerceBooleanProperty(value) });
    }

    protected readonly startDate$ = this.localStore.selectByKey("startDate");
    protected readonly finishDate$ = this.localStore.selectByKey("finishDate");
    protected readonly previousStartDate$ = this.localStore.selectByKey("previousStartDate");
    protected readonly previousFinishDate$ = this.localStore.selectByKey("previousFinishDate");
    protected readonly isWholeDayMode$ = this.localStore.selectByKey("isWholeDayMode");
    protected readonly hasTimeChange$ = this.startDate$
        .pipe(combineLatestWith(this.finishDate$, this.previousStartDate$, this.previousFinishDate$))
        .pipe(
            map(([startDate, finishDate, previousStartDate, previousFinishDate]) =>
                this.hasTimeChange(startDate, finishDate, previousStartDate, previousFinishDate)
            )
        );

    constructor(private readonly localStore: LocalComponentStore<MissionDatesComponentState>) {
        this.localStore.setState({
            startDate: new Date(),
            finishDate: new Date(),
            previousStartDate: undefined,
            previousFinishDate: undefined,
            isWholeDayMode: false,
        });
    }

    protected getDaysBetween(startDate: Date, endDate: Date): number {
        const daysBetween = DateUtils.daysBetween(startDate, endDate);

        return Math.max(daysBetween + 1, 1);
    }

    private hasTimeChange(startDate: Date, finishDate: Date, previousStartDate?: Date, previousFinishDate?: Date): boolean {
        if (!previousStartDate || !previousFinishDate) {
            return false;
        }

        return !DateUtils.compareDates(startDate, previousStartDate) || !DateUtils.compareDates(finishDate, previousFinishDate);
    }
}
