import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { EmptyStateMode } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { combineLatest, distinctUntilChanged } from "rxjs";
import { map } from "rxjs/operators";
import { DEFAULT_SORTING, Mission, Sorting } from "../../../models/mission-search.models";
import { MissionItemComponent } from "../mission-item/mission-item.component";

interface ListContainerComponentState {
    maxResultsSize: number | undefined;
    areFiltersSet: boolean;
    missions: Mission[];
    selectedMissionId: string | undefined;
    isProcessing: boolean;
}

@Component({
    selector: "dtm-mission-mission-search-list-container",
    templateUrl: "./list-container.component.html",
    styleUrls: ["./list-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ListContainerComponent {
    @Input() public isMapFeatureDisabled = false;

    @Input() public set maxResultsSize(value: number | undefined) {
        this.localStore.patchState({ maxResultsSize: value });
    }

    @Input() public set areFiltersSet(value: BooleanInput) {
        this.localStore.patchState({ areFiltersSet: coerceBooleanProperty(value) });
    }

    @Input() public set missions(value: Mission[] | undefined) {
        this.localStore.patchState({ missions: value ?? [] });
        this.areMissionsVisibleOnMapControl.setValue(false);
    }

    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set areMissionsInitiallyVisibleOnMap(value: BooleanInput) {
        this.areMissionsVisibleOnMapControl.setValue(coerceBooleanProperty(value));
    }

    @Input() public set selectedMissionId(value: string | undefined) {
        this.localStore.patchState({ selectedMissionId: value });

        if (value) {
            this.scrollToSelectedMission(value);
        }
    }

    @Output() public readonly filterConditionsOpen = new EventEmitter<void>();
    @Output() public readonly detailsShow = new EventEmitter<Mission>();
    @Output() public readonly selectedMissionChange = new EventEmitter<string>();

    protected readonly sortByControl = new FormControl<Sorting>(DEFAULT_SORTING, { nonNullable: true });
    protected readonly areMissionsVisibleOnMapControl = new FormControl<boolean>(false, { nonNullable: true });

    @Output() public readonly orderChange = this.sortByControl.valueChanges.pipe(distinctUntilChanged());
    @Output() public readonly missionsVisibilityOnMapChange = this.areMissionsVisibleOnMapControl.valueChanges.pipe(distinctUntilChanged());

    @ViewChild("missionsList", { read: ElementRef }) private readonly missionsListElement: ElementRef<HTMLElement> | undefined;
    @ViewChildren(MissionItemComponent) private readonly missionItemComponents: QueryList<MissionItemComponent> | undefined;

    protected readonly EmptyStateMode = EmptyStateMode;
    protected readonly areFiltersSet$ = this.localStore.selectByKey("areFiltersSet");
    protected readonly missions$ = this.localStore.selectByKey("missions");
    protected readonly areResultsTooLarge$ = combineLatest([this.missions$, this.localStore.selectByKey("maxResultsSize")]).pipe(
        map(([missions, maxResultsSize]) => (maxResultsSize ? missions.length > maxResultsSize : false))
    );
    protected readonly isResultsListVisible$ = combineLatest([this.areFiltersSet$, this.missions$, this.areResultsTooLarge$]).pipe(
        map(([areFiltersSet, missions, areResultsTooLarge]) => areFiltersSet && missions.length && !areResultsTooLarge)
    );
    protected readonly selectedMissionId$ = this.localStore.selectByKey("selectedMissionId");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly sortingOptions = Object.values(Sorting);

    constructor(private readonly localStore: LocalComponentStore<ListContainerComponentState>, private readonly element: ElementRef) {
        this.localStore.setState({
            maxResultsSize: undefined,
            areFiltersSet: false,
            missions: [],
            selectedMissionId: undefined,
            isProcessing: false,
        });
    }

    private scrollToSelectedMission(missionId: string | undefined) {
        this.missionItemComponents
            ?.find((missionItemComponent) => missionItemComponent.missionId === missionId)
            ?.scrollIntoMissionItem(this.missionsListElement?.nativeElement);
    }
}
