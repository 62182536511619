import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { map } from "rxjs/operators";
import { Uav, UavInfo } from "../../models/uav.models";

interface UavHeaderComponentState {
    uav: Uav | undefined;
    isShortMode: boolean;
    isManageDocumentsButtonVisible: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-uav-lib-uav-header",
    templateUrl: "./uav-header.component.html",
    styleUrls: ["./uav-header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UavHeaderComponent {
    @Input({ required: true }) public set uav(value: Uav | undefined) {
        this.localStore.patchState({ uav: value });
    }

    @Input() public set isShortMode(value: BooleanInput) {
        this.localStore.patchState({ isShortMode: coerceBooleanProperty(value) });
    }

    @Input() public set isManageDocumentsButtonVisible(value: BooleanInput) {
        this.localStore.patchState({ isManageDocumentsButtonVisible: coerceBooleanProperty(value) });
    }

    @Output() public documentsManage = new EventEmitter<void>();

    protected readonly uav$ = this.localStore.selectByKey("uav").pipe(RxjsUtils.filterFalsy());
    protected readonly uavInfo$ = this.uav$.pipe(map((uav) => this.prepareUavInfo(uav)));
    protected readonly isShortMode$ = this.localStore.selectByKey("isShortMode");
    protected readonly isManageDocumentsButtonVisible$ = this.localStore.selectByKey("isManageDocumentsButtonVisible");

    constructor(private readonly localStore: LocalComponentStore<UavHeaderComponentState>) {
        this.localStore.setState({
            uav: undefined,
            isShortMode: false,
            isManageDocumentsButtonVisible: false,
        });
    }

    private prepareUavInfo(uav: Uav): UavInfo {
        return {
            name: uav.name,
            manufacturerName: uav.model.manufacturer,
            modelName: uav.model.name,
            modelType: uav.model.type,
            serialNumbers: uav.serialNumbers,
            uavClasses: uav.uavClasses,
            imageUrl: uav.model.imageUrl,
            ownerName: uav.ownerName,
            isShared: uav.isShared,
            isCustom: uav.isCustom,
            isCeCompliant: uav.isCeCompliant,
            isSwarm: uav.isSwarm,
        };
    }
}
