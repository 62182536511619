import { NavigationAccuracy, Tracker, UavClass } from "@dtm-frontend/shared/uav";
import { Page, UavType } from "@dtm-frontend/shared/ui";

export enum UavErrorType {
    Unknown = "Unknown",
    CannotGetUavs = "CannotGetUavs",
    NotFound = "NotFound",
}

export interface UavError {
    type: UavErrorType;
}

export interface UavListFilterParams {
    page?: string;
    size?: string;
    serialNumber?: string;
    operatorNumber?: string;
}

export interface UavFilters {
    serialNumber: string;
    operatorNumber: string;
}

export interface UavsWithPages {
    content: UavListItem[];
    pagination: Page;
}

export interface UavListItem {
    id: string;
    operatorId: string;
    operatorNumber: string;
    ownerName: string;
    manufacturerAndModel: string;
    type: UavType;
    serialNumbers: string[];
    isCustom: boolean;
    isSwarm: boolean;
    uavClasses: UavClass[];
}

export interface UavCapabilities {
    trackers: Tracker[];
    navigationAccuracyItems: NavigationAccuracy[];
}
