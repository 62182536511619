import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, TemplateRef } from "@angular/core";
import {
    AircraftEntity,
    FlightPositionUpdate,
    FlightPositionUpdateType,
    HemsEventData,
    RouteAreaTypeId,
    RouteData,
    TimeRange,
    Trajectory,
} from "@dtm-frontend/shared/ui";
import { ArrayUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { ActionType, CesiumService } from "@pansa/ngx-cesium";
import { AllGeoJSON, BBox } from "@turf/helpers";
import { Subject, combineLatestWith, filter, from, iif, merge, mergeMap, of, share, startWith, switchMap } from "rxjs";
import { map, tap } from "rxjs/operators";
import { CameraHelperService } from "../../services/camera-helper.service";
import { RouteViewerService } from "../../services/route-viewer/route-viewer.service";
import { MARKED_TRAJECTORY_VISUAL_DATA, TRAJECTORY_VISUAL_DATA } from "./route-viewer.data";

/* eslint-disable @typescript-eslint/no-explicit-any*/
declare const Cesium: any; // TODO: DTM-966

interface RouteViewerComponentState<MissionData> {
    routeData: RouteData<MissionData> | undefined;
    drawableFeatures: RouteAreaTypeId[];
    nearbyMissionsDrawableFeatures: RouteAreaTypeId[];
    activeEntitiesId: Set<string>;
    pinTemplate: TemplateRef<any> | null;
    flightPositionUpdate: FlightPositionUpdate | undefined;
    flightPinTemplate: TemplateRef<{ $implicit: AircraftEntity }> | null;
    trajectories: Map<string, Trajectory[]>;
    hemsEventData: HemsEventData[];
    timeRange: TimeRange | undefined;
    selectedAircraftEntity: AircraftEntity | undefined;
}

const VISIBLE_AREA_BUFFER_SCALE = 0.5;

@UntilDestroy()
@Component({
    selector: "dtm-map-route-viewer[routeData]",
    templateUrl: "./route-viewer.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class RouteViewerComponent<T> implements AfterViewInit, OnDestroy {
    @Input()
    public set routeData(value: RouteData<T> | undefined) {
        this.localStore.patchState({ routeData: value });
    }
    @Input()
    public set drawableFeatures(value: RouteAreaTypeId[] | undefined) {
        this.localStore.patchState({ drawableFeatures: [...new Set(value)] });
    }
    @Input()
    public set nearbyMissionsDrawableFeatures(value: RouteAreaTypeId[] | undefined) {
        this.localStore.patchState({ nearbyMissionsDrawableFeatures: value ? ArrayUtils.unique(value) : undefined });
    }
    @Input()
    public set pinTemplate(value: TemplateRef<any> | null) {
        this.localStore.patchState({ pinTemplate: value });
    }
    @Input()
    public set flightPositionUpdate(value: FlightPositionUpdate | undefined) {
        this.localStore.patchState({ flightPositionUpdate: value });
    }
    @Input()
    public set flightPinTemplate(value: TemplateRef<{ $implicit: AircraftEntity }> | null) {
        this.localStore.patchState({ flightPinTemplate: value });
    }
    @Input()
    public set zoomTo(value: AllGeoJSON | undefined) {
        if (!value) {
            return;
        }
        this.cameraHelperService.flyToGeoJSON(value);
    }
    @Input()
    public set trajectories(value: Map<string, Trajectory[]>) {
        this.localStore.patchState({ trajectories: value });
    }
    @Input()
    public set hemsEventData(value: HemsEventData[] | undefined) {
        this.localStore.patchState({ hemsEventData: value ?? [] });
    }
    @Input()
    public set timeRange(value: TimeRange | undefined) {
        this.localStore.patchState({ timeRange: value });
    }
    @Input()
    public set dataIdentifierKey(value: keyof T) {
        this.dataIdKey = value;
    }

    @Output()
    public visibleAreaChanged: EventEmitter<BBox> = new EventEmitter();

    protected readonly checkAndUpdateFlightEntities$ = new Subject<BBox>();

    protected readonly flightData$ = this.initFlightData().pipe(share());
    protected readonly trajectoryEntities$ = this.initTrajectoryData();
    protected readonly markedTrajectoryEntities$ = this.initMarkedTrajectoryData();
    protected readonly flightPinTemplate$ = this.localStore.selectByKey("flightPinTemplate");
    protected readonly routeData$ = this.localStore.selectByKey("routeData");
    protected readonly drawableFeatures$ = this.localStore.selectByKey("drawableFeatures");
    protected readonly nearbyMissionsDrawableFeatures$ = this.localStore.selectByKey("nearbyMissionsDrawableFeatures");
    protected readonly pinTemplate$ = this.localStore.selectByKey("pinTemplate");
    protected readonly detailedAircraftEntity$ = this.localStore.selectByKey("selectedAircraftEntity").pipe(
        switchMap((selectedEntity) =>
            iif(
                () => !!selectedEntity,
                this.flightData$.pipe(
                    filter((update) => update?.entity.trackerIdentifier === selectedEntity?.trackerIdentifier),
                    map((update) => (update.actionType === ActionType.ADD_UPDATE ? update?.entity : undefined)),
                    startWith(selectedEntity)
                ),
                of(undefined)
            )
        )
    );

    protected Cesium = Cesium;
    protected TRAJECTORY_VISUAL_DATA = TRAJECTORY_VISUAL_DATA;
    protected TRAJECTORY_WARNING_VISUAL_DATA = MARKED_TRAJECTORY_VISUAL_DATA;

    private dataIdKey: keyof T | undefined;

    constructor(
        private readonly localStore: LocalComponentStore<RouteViewerComponentState<T>>,
        private readonly cameraHelperService: CameraHelperService,
        private readonly routeViewerService: RouteViewerService<T>,
        private readonly cesiumService: CesiumService
    ) {
        this.localStore.setState({
            routeData: undefined,
            drawableFeatures: [],
            nearbyMissionsDrawableFeatures: [],
            activeEntitiesId: new Set(),
            pinTemplate: null,
            flightPositionUpdate: undefined,
            flightPinTemplate: null,
            trajectories: new Map(),
            hemsEventData: [],
            timeRange: undefined,
            selectedAircraftEntity: undefined,
        });
    }

    public ngOnDestroy(): void {
        const camera = this.cesiumService.getViewer().camera;
        camera?.moveStart.removeEventListener(this.handelCameraMoveStartEvent);
        camera?.moveEnd.removeEventListener(this.handleCameraMoveEndEvent);
    }

    public ngAfterViewInit(): void {
        const camera = this.cesiumService.getViewer().camera;
        camera?.moveStart.addEventListener(this.handelCameraMoveStartEvent, this);
        camera?.moveEnd.addEventListener(this.handleCameraMoveEndEvent, this);
    }

    public selectAircraftEntity(entity: AircraftEntity | undefined): void {
        this.localStore.patchState({ selectedAircraftEntity: entity });
    }

    protected clearSelectedAircraftEntity(): void {
        this.localStore.patchState({ selectedAircraftEntity: undefined });
    }

    protected getFlightPinTemplateContext(entity: AircraftEntity): { $implicit: AircraftEntity } {
        return { $implicit: entity };
    }

    private handleCameraMoveEndEvent() {
        const viewBox = this.cameraHelperService.getViewBox(VISIBLE_AREA_BUFFER_SCALE);
        if (!viewBox) {
            return;
        }
        this.visibleAreaChanged.next(viewBox);
        this.checkAndUpdateFlightEntities$.next(viewBox);
    }

    private handelCameraMoveStartEvent() {
        const viewBox = this.cameraHelperService.getViewBox(VISIBLE_AREA_BUFFER_SCALE);
        if (!viewBox) {
            return;
        }
        this.checkAndUpdateFlightEntities$.next(viewBox);
    }

    private initFlightData() {
        const buffer: Map<string, FlightPositionUpdate> = new Map();

        return merge(this.localStore.selectByKey("flightPositionUpdate"), this.removeEntitiesOutsideVisibleArea(buffer)).pipe(
            map((update) => this.markIsConnectionLost(buffer, update)),
            RxjsUtils.filterFalsy(),
            tap((update) => {
                if ([FlightPositionUpdateType.Update, FlightPositionUpdateType.Start].includes(update.updateType)) {
                    buffer.set(update.trackerIdentifier, update);
                } else if (update.updateType === FlightPositionUpdateType.End && buffer.has(update.trackerIdentifier)) {
                    buffer.delete(update.trackerIdentifier);
                }
            }),
            map((data) => this.routeViewerService.transformFlightUpdateToFlightEntity(data)),
            tap(() => {
                this.cesiumService.getScene().requestRender();
            })
        );
    }

    private markIsConnectionLost(
        buffer: Map<string, FlightPositionUpdate>,
        update?: FlightPositionUpdate
    ): FlightPositionUpdate | undefined {
        if (update?.updateType !== FlightPositionUpdateType.ConnectionLost) {
            return update;
        }
        const bufferedUpdate = buffer.get(update.trackerIdentifier);
        if (!bufferedUpdate) {
            return;
        }

        bufferedUpdate.isConnectionLost = true;

        return bufferedUpdate;
    }

    private removeEntitiesOutsideVisibleArea(buffer: Map<string, FlightPositionUpdate>) {
        return this.checkAndUpdateFlightEntities$.pipe(
            mergeMap(([minX, minY, maxX, maxY]) =>
                from(
                    [...buffer.values()]
                        .filter(
                            ({ position }) =>
                                !(
                                    position?.longitude &&
                                    position.latitude &&
                                    minX <= position.longitude &&
                                    position.longitude <= maxX &&
                                    minY <= position.latitude &&
                                    position.latitude <= maxY
                                )
                        )
                        .map((update) => ({ ...update, updateType: FlightPositionUpdateType.End }))
                )
            )
        );
    }

    private initTrajectoryData() {
        const activeEntitiesIds: Map<string, Set<string>> = new Map();

        return this.localStore
            .selectByKey("trajectories")
            .pipe(
                mergeMap((trajectoriesMap) =>
                    trajectoriesMap.size
                        ? from([...trajectoriesMap.entries()]).pipe(
                              mergeMap(([identifier, trajectories]) =>
                                  this.routeViewerService.transformRouteDataToTrajectoryEntities(
                                      trajectories,
                                      ["trajectories"],
                                      activeEntitiesIds,
                                      identifier
                                  )
                              )
                          )
                        : this.routeViewerService.clearTrajectories(activeEntitiesIds)
                )
            );
    }

    private initMarkedTrajectoryData() {
        const activeEntitiesIds: Map<string, Set<string>> = new Map();

        return this.localStore.selectByKey("trajectories").pipe(
            combineLatestWith(this.localStore.selectByKey("routeData")),
            map(([trajectoriesMap, routeData]) =>
                [...trajectoriesMap.entries()].map(([id, trajectory]) => {
                    if (!this.dataIdKey) {
                        return;
                    }

                    const route =
                        routeData?.data?.[this.dataIdKey] === id
                            ? routeData
                            : routeData?.nearbyMissionsData?.find(
                                  (nearbyMission) => this.dataIdKey && nearbyMission.data?.[this.dataIdKey] === id
                              );

                    return {
                        trajectory,
                        route,
                        id,
                    };
                })
            ),
            mergeMap((data) =>
                data.length
                    ? from(data).pipe(
                          mergeMap((trajectoryData) =>
                              this.routeViewerService.transformRouteDataToMarkedTrajectoryEntities(
                                  trajectoryData?.id ?? "",
                                  trajectoryData?.trajectory,
                                  trajectoryData?.route,
                                  ["trajectories"],
                                  activeEntitiesIds
                              )
                          )
                      )
                    : this.routeViewerService.clearTrajectories(activeEntitiesIds)
            )
        );
    }
}
